import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../components';
import OutlinedTextField from '../../../components/HookFormFields/TextField';
import { DEVICE_TYPE } from '../../../config/devices';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { ApplicationContext } from '../../../modules/commonTypes';
import actions, { selectors } from '../../../modules/devices';
import { addAxisDoorStationDevice } from '../actions';
import AddDeviceModal from '../AddDeviceModal';
import DeviceTitle from '../DeviceControl/DeviceTitle';
import FinishedStep from '../FinishedStep';
import messagesFinished from '../FinishedStep/messages';
import { MacAddressField } from './MacAddressField';
import messages from './messages';
import { getAxisDoorStationValidationSchema } from './validationSchema';

export interface IAxisDoorStationForm {
  onClearModal: () => void;
  onClose: () => void;
  onSelectDevice: (value?: string) => void;
  open: boolean;
}

interface IFormData {
  macAddress: string;
  name: string;
}

export function AxisDoorStationForm({ onClearModal, onClose, onSelectDevice, open }: IAxisDoorStationForm) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const finishedData = useSelector(selectors.getFinishedDataDeviceModal);
  const isFinished = useSelector(selectors.isFinishedAddDeviceModal);
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);

  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getValidationSchema = getAxisDoorStationValidationSchema(formatMessage, companyId, siteId);
  const formData = useForm<IFormData>({
    defaultValues: {
      macAddress: '',
      name: '',
    },
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema),
  });

  useEffect(() => {
    if (formData.formState.isSubmitting) {
      setIsSubmitting(true);
    }
  }, [formData.formState.isSubmitting]);

  useEffect(() => {
    if (isFinished && step !== 2) {
      setStep(2);
    }
  }, [isFinished, step]);

  const onCreateNewDevice = () => {
    setStep(1);
    formData.reset();
    onSelectDevice(undefined);
    onClearModal();
    dispatch(actions.addDeviceClearModalData);
  };

  function handleSubmit(values: IFormData) {
    dispatch(addAxisDoorStationDevice(values));
  }

  function getActions() {
    if (step === 1) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={onCreateNewDevice} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messages.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.formActionCancelBtn} />
          </Button>
          <LoadingButton
            color="primary"
            disabled={!formData.formState.isValid}
            endIcon={<ArrowForwardIcon />}
            isLoading={isSubmitting}
            onClick={formData.handleSubmit(handleSubmit)}
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messages.formActionNextBtn} />
          </LoadingButton>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messages.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onCreateNewDevice}>
            <FormattedMessage {...messagesFinished.formActionAddNewDeviceBtn} />
          </Button>
          <Button color="primary" endIcon={<DoneIcon />} onClick={onClose} type="button" variant="contained">
            <FormattedMessage {...messagesFinished.formActionCloseBtn} />
          </Button>
        </>
      );
    }

    return null;
  }

  return (
    <FormProvider {...formData}>
      <form autoComplete="off" noValidate onSubmit={formData.handleSubmit(handleSubmit)}>
        <AddDeviceModal
          actions={getActions()}
          title={<DeviceTitle device={DEVICE_TYPE.DOOR_STATION} />}
          open={open}
          onClose={onClose}
        >
          {step === 1 && (
            <Stack spacing={2} mt={2}>
              <OutlinedTextField
                autoFocus
                InputProps={{ inputComponent: MacAddressField }}
                label={<FormattedMessage {...messages.axisDoorStationFormMacAddressLabel} />}
                name="macAddress"
                required
                fullWidth
              />
              <OutlinedTextField
                helperText={<FormattedMessage {...messages.axisDoorStationFormNameExample} />}
                label={<FormattedMessage {...messages.axisDoorStationFormNameLabel} />}
                name="name"
                required
                fullWidth
              />
            </Stack>
          )}
          {step === 2 && <FinishedStep device={finishedData} deviceModalType={DEVICE_TYPE.DOOR_STATION} />}
        </AddDeviceModal>
      </form>
    </FormProvider>
  );
}
