import { createSelector } from 'reselect';
import { IMPORT_USERS_STATES, ROLES } from '../../config/consts';
import { isApartmentAdmin } from '../../helpers/user';
import { isSiteMdu } from '../sites/selectors';
import { getFeUserRoles, getUsersOrderField } from './helpers';
import { CONTEXT } from './types';

export const getCurrentUserSelector = ({ user }) => user.data;

export const getCurrentUserIdSelector = ({ user }) => user.data.id || undefined;

export const getCurrentUserDetail = ({ user }) => user.userDetail.data;

export const getCurrentUserDetailRole = createSelector(
  getCurrentUserDetail,
  (userDetailData) => userDetailData?.role || ''
);

export const getCurrentUserDetailId = ({ user }) => user.userDetail?.data?.id || undefined;

export const getCurrentUserDetailIsFetching = ({ user }) => user.userDetail.isFetching;

export const getUserListSelector = (context) => (state) => {
  if (![CONTEXT.SITE_USERS, CONTEXT.COMPANY_ADMINS].includes(context)) {
    throw new Error('No supported context');
  }
  const data = state.user[context];
  return {
    currentUser: getCurrentUserSelector(state),
    data: data.data || [],
    didInvalid: data.didInvalid || false,
    filter: data.filter || { fulltext: '' },
    loading: data.isFetching || false,
    order: getUsersOrderField(data.order),
    page: data.page || 1,
    resultsFiltered: data.resultsFiltered || 0,
    resultsTotal: data.resultsTotal || 0,
    rowsPerPage: data.rowsPerPage || 15,
  };
};

export const isSuperadmin = ({ user }) => user.data?.roles?.some((r) => ROLES.SUPERADMIN_ROLES.includes(r)) || false;

export const isMarketer = ({ user }) => user.data?.roles?.includes(ROLES.MARKETING) || false;

export const getUserCompanies = ({ user }) => user.data?.companies || [];

export const getUserSitesWithAdminRole = (state) => {
  const sites = [];

  state?.user?.data?.companies.forEach((item) => {
    if (item.sites.length > 0) {
      item.sites.forEach((site) => {
        if (site.roles.includes(ROLES.ADMIN)) {
          sites.push(site.id);
        }
      });
    }
  });

  return sites;
};

export const isDetailInvalid = ({ user }) => user.userDetail.didInvalid;

export const userDetailCompanySites = createSelector(
  ({ company }) => company.data.id,
  ({ user }) => user.userDetail.data?.companies,
  (companyId, userCompanies) => userCompanies?.find((item) => item.id === companyId)?.sites || []
);

export const userDetailSiteApartments = createSelector(
  userDetailCompanySites,
  ({ sites }) => sites.data.id,
  (sites, siteId) => sites?.find((item) => item.id === siteId)?.apartments || []
);

export const getUserRoles = createSelector(
  ({ user }) => user.data,
  ({ sites }) => sites.data,
  ({ company }) => company.data,
  (user, company, site) => {
    let results = [];
    const { companies, id, roles } = user;
    if (roles && roles.length > 0) {
      results = [...results, ...roles];
    }

    const getRolesModule = (moduleName) => {
      const companyRoles = moduleName.roles || [];
      for (const role in companyRoles) {
        if ((companyRoles[role] || []).includes(id)) {
          results.push(role);
        }
      }
    };

    isApartmentAdmin(companies) && results.push(ROLES.APARTMENT_ADMIN);
    if (company !== undefined) {
      getRolesModule(company);
    }
    if (site !== undefined) {
      getRolesModule(site);
    }

    return results;
  }
);

export const deleteUserModalRoles = createSelector(
  ({ user }) => user.deleteModal.user,
  ({ sites }) => sites.data?.id,
  ({ company }) => company.data?.id,
  (currentUserData, currentSiteId, currentCompanyId) => {
    const currentCompany = currentUserData?.companies?.find((item) => item.id === currentCompanyId);
    const currentSite = currentCompany?.sites?.find((item) => item.id === currentSiteId);

    return {
      companyRoles: currentCompany?.roles || [],
      siteRoles: currentSite?.roles || [],
    };
  }
);

export const currentUserRoles = createSelector(
  ({ sites }) => sites.data?.id,
  ({ company }) => company.data?.id,
  ({ common }) => common.companySwitcher.data,
  (currentSiteId, currentCompanyId, currentCompanies) => {
    const currentCompany = currentCompanies?.find((item) => item.id === currentCompanyId);
    const currentSite = currentCompany?.sites?.find((item) => item.id === currentSiteId);

    return {
      companyRoles: currentCompany?.roles || [],
      siteRoles: currentSite?.roles || [],
    };
  }
);

export const getCurrentUserRoles = createSelector(
  (state) => currentUserRoles(state),
  (currentRoles) => {
    const { companyRoles, siteRoles } = currentRoles;

    return getFeUserRoles(siteRoles, companyRoles);
  }
);

export const isCompanyOrSiteAdmin = createSelector(
  ({ user }) => user.data?.companies,
  (companies) => {
    const isCompanyAdmin = !!companies?.find((company) => company?.roles?.indexOf(ROLES.COMPANY_ADMIN) >= 0);
    const isSiteAdmin = !!companies?.some((company) =>
      company?.sites?.some((site) => site?.roles?.includes(ROLES.ADMIN))
    );

    return isCompanyAdmin || isSiteAdmin;
  }
);

export const isDidInvalidUserPin = ({ user }) => user.userPin.didInvalid;
export const getUserPin = ({ user }) => user.userPin;
export const getUserRfidCard = ({ user }) => user.userRfidCard;
export const isInvalidUserRfidCard = ({ user }) => user.userRfidCard.didInvalid;

export const getUserPinData = ({ user }) => user?.userPin?.data || null;
export const getUsersPin = ({ user }) => user.userPin.data?.pin || null;

export const getGeneratedUserPinData = (state) =>
  state.user.userPin.generatePin.data || {
    active: false,
    id: '',
    pin: '',
  };
export const hasGeneratedUserPinError = (state) => !!state.user.userPin.generatePin.error;

export const getUserApartmentModalIsFetching = ({ user }) => user.userApartmentModal.isFetching;

export const getUserApartmentModalData = createSelector(
  ({ user }) => user.userApartmentModal,
  (userApartmentModal) =>
    userApartmentModal.user || {
      email: '',
      firstName: '',
      id: '',
      lastName: '',
    }
);

const siteUsers = (state) => state.user[CONTEXT.SITE_USERS];

export const getSiteUsers = createSelector(siteUsers, (users) => users.data);

export const getUserRfidCardData = ({ user }) => user.userRfidCard.data;
export const getUsersRfid = ({ user }) => user.userRfidCard.data?.rfidCode;

export const currentImportUsersState = ({ user }) => user.importUsers.currentImport.data?.state;
export const currentImportUsersData = ({ user }) => user.importUsers.currentImport.data;
export const currentImportUsersDataFailed = createSelector(
  currentImportUsersData,
  (importData) => importData?.failedRows || []
);

export const currentImportUsersRunning = ({ user }) =>
  user.importUsers.currentImport.data?.state === IMPORT_USERS_STATES.PROCESSING;

export const getUserAccessGroupsModalShow = ({ user }) => user.userAccessGroupsModal.show;
export const getUserAccessGroupsModalUserId = ({ user }) => user.userAccessGroupsModal.userId;

export const getUserModalContext = ({ user }) => user.userModal.context;

export const getUserModalData = ({ user }) => user.userModal.data;

export const getUserModalDataId = ({ user }) => user.userModal.data?.id || null;

export const getUserModalIsEdit = ({ user }) => user.userModal.isEdit;

export const getUserMy2NAppAccessEnabledStatus = (state) => state.user.my2NAppAccessStatus.data?.enabled || false;

export const hasUserMy2NAppAccessAuthKey = (state) => !!state.user.my2NAppAccessStatus.data?.authIdHash || false;

export const isUserMy2NAppAccessStatusError = (state) => !!state.user.my2NAppAccessStatus.error;

export const isUserMy2NAppAccessStatusInvalid = (state) => state.user.my2NAppAccessStatus.didInvalid;

export const getSiteUsersDataGridProps = createSelector(siteUsers, isSiteMdu, (users, isMdu) => ({
  data: users.data || [],
  didInvalid: users.didInvalid,
  filter: users.filter,
  isFetching: users.isFetching,
  order: !users?.data?.length && isMdu ? 'apartment' : users.order,
  page: users.page,
  resultsFiltered: users.resultsFiltered,
  resultsTotal: users.resultsTotal,
  rowsPerPage: users.rowsPerPage,
}));
