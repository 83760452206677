import { Box } from '@mui/material';
import React from 'react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface IRouterLinkProps {
  to: string;
  children: ReactNode;
  onClick?: () => void;
}

const linkStyles = {
  '& a': {
    color: 'primary.main',
  },
  '& a:visited': {
    color: 'primary.main',
  },
};

export function RouterLink(props: IRouterLinkProps) {
  return (
    <Box sx={linkStyles}>
      <Link to={props.to} color="secondary" onClick={props.onClick}>
        {props.children}
      </Link>
    </Box>
  );
}
