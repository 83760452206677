import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface IFormControlLabel extends Omit<CheckboxProps, 'control'> {
  name: string;
  label: string | React.JSX.Element;
  color?: CheckboxProps['color'];
}

function Checkbox({ color, label, name, ...other }: IFormControlLabel) {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControlLabel
          sx={other.required ? { '& .MuiStack-root': { display: 'block' } } : undefined}
          label={label}
          control={<MuiCheckbox {...field} {...other} color={color} checked={!!field.value} />}
        />
      )}
    />
  );
}

export default Checkbox;
