import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentFormFieldCreateNewApartment: {
    defaultMessage: 'Create new apartment',
    description: 'Create new apartment',
    id: 'oneStep.apartment.createNewApartment',
  },
  apartmentFormFieldEmptySelect: {
    defaultMessage: 'Select, or create a new one',
    description: 'Apartment empty select',
    id: 'deviceModal.oneStep.apartment.emptySelect',
  },
  apartmentFormFieldHelperText: {
    defaultMessage: 'You can add the device to an apartment, for easier administration',
    description: 'Apartment helper text',
    id: 'deviceModal.oneStep.apartment.helperText',
  },
  apartmentFormFieldLabel: {
    defaultMessage: 'Apartment',
    description: 'Apartment label',
    id: 'oneStep.apartment.label',
  },
  OneStepApartmentErrorDeviceLimitReached: {
    defaultMessage: 'Device limit reached for this apartment',
    id: 'oneStep.apartment.error.deviceLimitReached',
  },
});
