import CreditCardIcon from '@mui/icons-material/CreditCard';
import DialpadIcon from '@mui/icons-material/Dialpad';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useIntl } from 'react-intl';
import { SiteWithAcCredentialsSettingsResponse } from '../../apiRtk/be4feApi';
import { ReactComponent as TimeLimitedIcon } from '../../assets/time-limited.svg';
import featureFlags from '../../config/featureFlags';
import { UserCredentials } from '../../modules/commonTypes';
import My2NAppAccessDisabledIcon from '../_Icons/My2NAppAccessDisabledIcon';
import My2NAppAccessIcon from '../_Icons/My2NAppAccessIcon';
import { useHasFeature } from '../Feature/Feature';
import messages from '../SiteUsersDataGrid/messages';
import { getStatusTooltipTitle } from './statusTooltipTitle';
import { getVisibilityOfStatuses } from './statusVisibility';

export interface IUserAccessSettingsStatusProps {
  credentials: UserCredentials[];
  hiddenStatuses?: UserCredentials[];
  isTimeLimitedAccess?: boolean;
  siteData: SiteWithAcCredentialsSettingsResponse | null;
}

const UserAccessSettingsStatus = ({
  credentials,
  hiddenStatuses,
  isTimeLimitedAccess,
  siteData,
}: IUserAccessSettingsStatusProps) => {
  const isUserBleCredentialFFEnable = useHasFeature(featureFlags.USER_BLE_CREDENTIAL);
  const { isBTStatusVisible, isPinStatusVisible, isRFIDStatusVisible } = getVisibilityOfStatuses({
    hiddenStatuses,
    isUserBleCredentialFFEnable,
  });

  const { formatMessage } = useIntl();

  const isServiceActive = (service: UserCredentials) => credentials?.includes(service);
  const getIconColor = (isServiceActive: boolean) => (isServiceActive ? 'primary' : 'disabled');

  const isPinActive = isPinStatusVisible && isServiceActive(UserCredentials.PIN);
  const isRFIDActive = isRFIDStatusVisible && isServiceActive(UserCredentials.RFID);
  const isBTActive = isBTStatusVisible && isServiceActive(UserCredentials.BT);
  const isSiteBleActive = siteData?.services?.ACCESS_CONTROL?.bluetoothEnabled;

  const tooltipTitleValues = { br: <br /> };

  return (
    <Stack direction="row" sx={{ fontSize: 0 }} gap={1}>
      {isPinStatusVisible && (
        <Tooltip title={formatMessage(getStatusTooltipTitle(isPinActive, UserCredentials.PIN), tooltipTitleValues)}>
          <DialpadIcon color={getIconColor(isPinActive)} />
        </Tooltip>
      )}
      {isRFIDStatusVisible && (
        <Tooltip title={formatMessage(getStatusTooltipTitle(isRFIDActive, UserCredentials.RFID), tooltipTitleValues)}>
          <CreditCardIcon color={getIconColor(isRFIDActive)} />
        </Tooltip>
      )}
      {(isSiteBleActive || siteData === null) && isBTStatusVisible && (
        <Tooltip title={formatMessage(getStatusTooltipTitle(isBTActive, UserCredentials.BT), tooltipTitleValues)}>
          <span>
            <My2NAppAccessIcon color={getIconColor(isBTActive)} />
          </span>
        </Tooltip>
      )}
      {!isSiteBleActive && siteData !== null && (
        <Tooltip title={formatMessage(messages.userAccessSettingsStatusBluetoothDisabled, { br: <br /> })}>
          <span>
            <My2NAppAccessDisabledIcon color="disabled" />
          </span>
        </Tooltip>
      )}
      {isTimeLimitedAccess && (
        <Tooltip placement="right" title={formatMessage(messages.UserListUserGuestIconTooltip)}>
          <span>
            <SvgIcon sx={{ ml: 1 }} component={TimeLimitedIcon} color="primary" />
          </span>
        </Tooltip>
      )}
    </Stack>
  );
};

export default UserAccessSettingsStatus;
