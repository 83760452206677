import { trimStringsInObject } from '@my2n/js-helpers';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { PATHS } from '../config/consts';
import { isJwtExpired } from '../helpers/checkJwtExpiration';
import { buildUrlWithQueryParams, UrlQueryParamName, UrlQueryParamValueBoolean } from '../helpers/queryParams';
import { store } from '../modules/configureStore';
import { OryLoginStatus } from '../ory/types';

const STRING_KEYS_TO_EXCLUDE_FROM_TRIMMING = ['password'];

const encode = (value: string) => encodeURIComponent(value);

export const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  paramsSerializer: (params) => qs.stringify(params),
  validateStatus: () => true,
  withCredentials: true,
});

const unAuthorizedStatus = 401;
const isForbidden = 403;

axiosInstance.interceptors.response.use(onResponse, (error) => Promise.reject(error));

axiosInstance.interceptors.request.use(onRequest, (error) => Promise.reject(error));

const setApiKey = (apiKey?: string) => {
  if (apiKey) {
    axiosInstance.defaults.headers.common['X-Api-Key'] = apiKey;
  } else {
    delete axiosInstance.defaults.headers.common['X-Api-Key'];
  }
};

const PATCH_OPERATIONS = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  REPLACE: 'REPLACE',
  SET: 'SET',
};

export { axiosInstance as default, encode, PATCH_OPERATIONS, setApiKey };

const setUnAuthorized = () => {
  if (!window.location.href.includes(PATHS.LOGIN)) {
    let queryParams = undefined;

    const oryLoginStatus = store.getState().auth.oryLoginStatus;

    if (oryLoginStatus === OryLoginStatus.SessionConfirmed) {
      queryParams = { [UrlQueryParamName.IsOrySessionActive]: UrlQueryParamValueBoolean.True };
    }

    window.location.href = buildUrlWithQueryParams({ baseUrl: PATHS.LOGOUT, queryParams });
  }
};

function onRequest(config: AxiosRequestConfig): any {
  const token = config.headers?.common?.['X-Api-Key'];

  if (isJwtExpired(token)) {
    try {
      throw new axios.Cancel('Token expired');
    } catch {
      setUnAuthorized();
    }
  }

  const traceId = Array.from({ length: 16 }, () => Math.floor(Math.random() * 16).toString(16)).join('');
  return {
    ...config,
    data: trimStringsInObject(config.data, STRING_KEYS_TO_EXCLUDE_FROM_TRIMMING),
    headers: {
      ...config.headers,
      'X-B3-SpanId': traceId,
      'X-B3-TraceId': traceId,
    },
    params: trimStringsInObject(config.params, STRING_KEYS_TO_EXCLUDE_FROM_TRIMMING),
  };
}

function onResponse(response: AxiosResponse) {
  if (response.status === unAuthorizedStatus) {
    setUnAuthorized();
  }

  if (response.status === isForbidden && response.config?.url !== '/auth') {
    window.location.href = PATHS.USER_PROFILE;
  }

  return response;
}
