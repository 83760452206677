import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { OfflinePairingDto } from '../../../apiRtk/accessControlApi';
import { getRadioGroupBooleanValue } from '../Step4/Questions2/helpers/getRadioGroupBooleanValue';
import { FormFieldNames } from '../validationSchema';
import LocationIDs from './LocationIDs/LocationIDs';
import Questions from './Questions/Questions';

interface IStep3 {
  offlinePairingData: OfflinePairingDto;
}

function Step3({ offlinePairingData }: IStep3) {
  const formMethods = useFormContext();
  const acomValue = formMethods.watch(FormFieldNames.Acom);
  const noBLEValue = getRadioGroupBooleanValue(formMethods.watch(FormFieldNames.NoBLE));

  useEffect(() => {
    if (noBLEValue === false) {
      formMethods.setValue(FormFieldNames.ManageBLE, true);
    } else {
      formMethods.setValue(FormFieldNames.ManageBLE, false);
    }
  }, [noBLEValue]);

  useEffect(() => {
    formMethods.resetField(FormFieldNames.SelectId);
  }, [noBLEValue, acomValue]);

  return (
    <Box>
      <Questions offlinePairingDto={offlinePairingData} />
      {offlinePairingData?.offlinePairing && !offlinePairingData?.acom && offlinePairingData?.locationIds?.length && (
        <LocationIDs locationIds={offlinePairingData.locationIds} />
      )}
    </Box>
  );
}

export default Step3;
