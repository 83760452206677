import { Alert, Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

export function InitialWarning() {
  const { formatMessage } = useIntl();
  return (
    <Box>
      <Typography variant="body1" pb={2}>
        <FormattedMessage {...messages.configureMy2NAccessModal1Text1} />
      </Typography>
      <Alert severity="warning">
        <FormattedMessage {...messages.configureMy2NAccessModal1Warn} />
      </Alert>
      <Typography variant="body1" pt={2}>
        <FormattedMessage
          {...messages.configureMy2NAccessModal1Text2}
          values={{
            link: (
              <Link href={formatMessage(messages.configureMy2NaccessModal1Text2LinkUrl)}>
                <FormattedMessage {...messages.configureMy2NaccessModal1Text2LinkText} />
              </Link>
            ),
          }}
        />
      </Typography>
    </Box>
  );
}
