import { defineMessages } from 'react-intl';

export default defineMessages({
  CreditPoolCardApartmentModelFreeWithIntercom: {
    defaultMessage: 'Free Mobile Video license with your 2N intercom is granted for one apartment',
    id: 'creditPoolCard.apartmentModel.freeWithIntercom',
  },
  creditPoolCardCode: {
    defaultMessage: 'Code',
    description: 'Credit pool card - code',
    id: 'creditPoolCard.code',
  },
  creditPoolCardCredits: {
    defaultMessage: '{count, plural, one {credit} other {credits}}',
    description: 'Credit pool card - credits',
    id: 'creditPoolCard.credits',
  },
  creditPoolCardCreditsCount: {
    defaultMessage: '{count, plural, one {<span>#</span> credit}' + ' other {<span>#</span> credits}} <text>+</text>',
    description: 'Credit pool card - credits count',
    id: 'creditPoolCard.creditsCount',
  },
  creditPoolCardDate: {
    defaultMessage: '({date})',
    description: 'Credit pool card - date',
    id: 'creditPoolCard.date',
  },
  creditPoolCardExpiration: {
    defaultMessage: '<text>Expiration:</text> {date}',
    description: 'Credit pool card - expiration',
    id: 'creditPoolCard.expiration',
  },
  creditPoolCardFreeDevices: {
    defaultMessage:
      '{count, plural, one {<span>#</span> device} other {<span>#</span> devices}}' + ' <text>for free</text>',
    description: 'Credit pool card - free devices',
    id: 'creditPoolCard.freeDevices',
  },
  creditPoolCardFreeDevicesApartments: {
    defaultMessage:
      '{count, plural, one {<span>#</span> device} other {<span>#</span> devices}}' +
      ' <text>for free</text>, {countApartment, plural, one {<span>#</span> apartment}' +
      ' other {<span>#</span> apartments}} <text>for free</text>',
    description: 'Credit pool card - free devices and apartments',
    id: 'creditPoolCard.freeDevicesApartments',
  },
  creditPoolCardNextConsumptionDate: {
    defaultMessage: '<text>Next consumption:</text> {date}',
    description: 'Credit pool card - next consumption date',
    id: 'creditPoolCard.nextConsumptionDate',
  },
  creditPoolCardNextPayment: {
    defaultMessage: 'Next payment:',
    description: 'Credit pool card - next payment',
    id: 'creditPoolCard.nextPayment',
  },
  creditPoolCardPriceVat: {
    defaultMessage: '<text>({price} {currency} incl. VAT)</text>',
    description: 'Credit pool card - priceVat',
    id: 'creditPoolCard.priceVat',
  },
  creditPoolCardRequirementForNextMonth: {
    defaultMessage:
      '<text>Requirement for next month:</text>' +
      ' {count, plural, one {<span>#</span> credit} other {<span>#</span> credits}}',
    description: 'Credit pool card - requirement for next month',
    id: 'creditPoolCard.requirementForNextMonth',
  },
  creditPoolCardTitle: {
    defaultMessage: 'Credit pool',
    description: 'Credit pool card - title',
    id: 'creditPoolCard.title',
  },
  creditPoolCardVoucher: {
    defaultMessage: 'Use voucher',
    description: 'Credit pool card - voucher',
    id: 'creditPoolCard.voucher',
  },
});
