import * as yup from 'yup';
import messages from './messages';

export function getValidationSchema(formatMessage) {
  return yup.object().shape({
    description: yup.string().max(1000, () => formatMessage(messages.accessGroupCreateModalInputDescriptionMaxLength)),
    name: yup
      .string()
      .required(formatMessage(messages.accessGroupCreateModalInputRequired))
      .max(255, formatMessage(messages.accessGroupCreateModalInputDescriptionMaxLength))
      .test('trim', formatMessage(messages.accessGroupCreateModalInputRequired), (val) => val?.trim()),
  });
}
