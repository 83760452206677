import { FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OfflinePairingDto } from '../../../../apiRtk/accessControlApi';
import { useGetGlobalInfo } from '../../../../hooks/useGetGlobalInfo';
import RadioGroup from '../../../HookFormFields/RadioGroup';
import { FormFieldNames } from '../../validationSchema';
import messages from './messages';

interface IQuestions {
  offlinePairingDto?: OfflinePairingDto;
}

function Questions({ offlinePairingDto }: IQuestions) {
  const { formatMessage } = useGetGlobalInfo();
  return (
    <>
      {!offlinePairingDto?.offlinePairing && !offlinePairingDto?.acom && (
        <>
          <Typography>
            <FormattedMessage {...messages.configureMy2NAccessModal3Text1NoBluetooth} />
          </Typography>
          <Typography variant="subtitle1" fontWeight={700} pt={2}>
            <FormattedMessage {...messages.configureMy2NAccessModal3QuestionDevice} />
          </Typography>
          <RadioGroup name={FormFieldNames.NoBLE}>
            <FormControlLabel
              control={<Radio />}
              value={true}
              label={formatMessage(messages.configureMy2NAccessModal3DeviceYes)}
            />
            <FormControlLabel
              control={<Radio />}
              value={false}
              label={formatMessage(messages.configureMy2NAccessModal3DeviceNo)}
            />
          </RadioGroup>
        </>
      )}

      {offlinePairingDto?.offlinePairing && offlinePairingDto?.acom && (
        <>
          <Typography>
            <FormattedMessage {...messages.configureMy2NAccessModal3Text1Acom} />
          </Typography>
          <Typography variant="subtitle1" fontWeight={700} pt={2}>
            <FormattedMessage {...messages.configureMy2NAccessModal3QuestionAcom} />
          </Typography>
          <RadioGroup name={FormFieldNames.Acom}>
            <FormControlLabel
              control={<Radio />}
              value={true}
              label={formatMessage(messages.configureMy2NAccessModal3AcomYes)}
            />
            <FormControlLabel
              control={<Radio />}
              value={false}
              label={formatMessage(messages.configureMy2NAccessModal3AcomNo)}
            />
          </RadioGroup>
        </>
      )}
    </>
  );
}

export default Questions;
