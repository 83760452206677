import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import SettingsIcon from '@mui/icons-material/Settings';
import { FormHelperText, Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  GuestPinSettingsDto,
  SiteBluetoothEnabledDto,
  useGetSiteCredentialsSettingQuery,
  useUpdateSiteBluetoothMutation,
  useUpdateSiteGuestPinSettingsMutation,
} from '../../../apiRtk/accessControlApi';
import { useGetSiteQuery } from '../../../apiRtk/be4feApi';
import { Alert } from '../../../components';
import ConfigureMy2NAccessModal from '../../../components/ConfigureMy2NAccessModal/ConfigureMy2NAccessModal';
import DeactivateMy2NAccessModal from '../../../components/DeactivateMy2nAccessModal/DeactivateMy2NAccessModal';
import { useHasFeature } from '../../../components/Feature/Feature';
import Checkbox from '../../../components/HookFormFields/Checkbox';
import { OutlinedTextField } from '../../../components/HookFormFields/TextField';
import featureFlags from '../../../config/featureFlags';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { useGetGlobalInfo } from '../../../hooks/useGetGlobalInfo';
import { useModal } from '../../../hooks/useModal';
import { TYPE_DESCRIPTOR } from '../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../modules/notifications/actions';
import { loadSite } from '../../../modules/sites/actions';
import styles from '../styles';
import messages from './messages';
import { FormFieldNames, getDefaultValues, getValidationSchema } from './validationSchema';

function Access() {
  const isSiteBleFFEnable = useHasFeature(featureFlags.SITE_BLE);
  const { dispatch, formatMessage } = useGetGlobalInfo();
  const { companyId, siteId } = useGetLoadedParams();
  const {
    data: siteData,
    isLoading: siteDataIsLoading,
    refetch: siteDataRefetch,
  } = useGetSiteQuery({ companyId: companyId!, siteId: siteId! });
  const [updateSiteGuestPinSettings, { isLoading: updateSiteGuestPinSettingsIsLoading }] =
    useUpdateSiteGuestPinSettingsMutation();
  const [updateSiteBluetooth] = useUpdateSiteBluetoothMutation();
  const { Modal: DeactivateMy2NAppAccess, onOpen: openDeactivateMy2NAppAccessModal } = useModal({
    Modal: DeactivateMy2NAccessModal,
  });
  const { Modal: ConfigureMy2NAppAccess, onOpen: openConfigureMy2NAppSettingsModal } = useModal({
    Modal: ConfigureMy2NAccessModal,
  });
  const { data: siteCredentialsSettingData } = useGetSiteCredentialsSettingQuery({
    companyId: companyId!.toString(),
    siteId: siteId!.toString(),
  });

  const formMethods = useForm({
    defaultValues: getDefaultValues(siteData),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage)),
  });

  useEffect(() => {
    if (siteData) {
      formMethods.reset({
        [FormFieldNames.SiteBLE]: siteData?.services.ACCESS_CONTROL?.bluetoothEnabled,
        [FormFieldNames.GuestLength]: siteData?.services.ACCESS_CONTROL?.guestPinLength,
        [FormFieldNames.GuestQR]: siteData?.services.ACCESS_CONTROL?.guestShowQr,
      });
    }
  }, [siteData]);

  function refetchSiteData() {
    siteDataRefetch();
    dispatch(loadSite(companyId, siteId));
  }

  function handleDisableMy2NAppAccess() {
    updateSiteBluetooth({
      companyId: companyId!.toString(),
      siteBluetoothEnabledDto: {
        bluetoothEnabled: false,
      } as SiteBluetoothEnabledDto,
      siteId: siteId!.toString(),
    })
      .unwrap()
      .then(() => {
        dispatch(addSnackbarSuccess({ ...messages.siteSettingsAccessMy2NappMsgSucc }));
        refetchSiteData();
      })
      .catch(() => {
        dispatch(addSnackbarError({ ...messages.siteSettingsAccessMy2NappMsgErr }, TYPE_DESCRIPTOR));
      });
  }

  function handleSubmit() {
    const formValues = formMethods.getValues();

    const guestPinSettingsDto: GuestPinSettingsDto = {
      guestPinLength: formValues.guestLength,
      guestShowQr: formValues.guestQR,
    };

    updateSiteGuestPinSettings({
      companyId: companyId!.toString(),
      guestPinSettingsDto,
      siteId: siteId!.toString(),
    })
      .unwrap()
      .then(() => {
        dispatch(addSnackbarSuccess({ ...messages.siteSettingsAccessMsgSucc }));
      })
      .catch(() => {
        dispatch(addSnackbarError({ ...messages.siteSettingsAccessMsgErr }, TYPE_DESCRIPTOR));
      });
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={12} xs={12}>
              <Typography variant="body1" fontWeight={700}>
                <FormattedMessage {...messages.siteSettingsAccessTitle1} />
              </Typography>
              {!siteDataIsLoading && (
                <Stack spacing={3} sx={{ mb: 3, mt: 3 }}>
                  <OutlinedTextField
                    sx={{ width: '167px' }}
                    label={formatMessage(messages.siteSettingsAccessGuestLengthLabel)}
                    name={FormFieldNames.GuestLength}
                    helperText={<FormattedMessage {...messages.siteSettingsAccessGuestLengthHelper} />}
                    InputProps={{
                      endAdornment: (
                        <Typography color="secondary">
                          <FormattedMessage {...messages.siteSettingsAccessGuestLengthUnit} />
                        </Typography>
                      ),
                    }}
                  />
                  <Checkbox
                    name={FormFieldNames.GuestQR}
                    label={<FormattedMessage {...messages.siteSettingsAccessGuestQrLabel} />}
                  />
                </Stack>
              )}
              <Typography>
                <FormattedMessage {...messages.siteSettingsAccessText1} />
              </Typography>
            </Grid>

            <Grid item lg={4} md={12} xs={12}>
              <Typography variant="body1" fontWeight={700}>
                <FormattedMessage {...messages.siteSettingsAccessTitle2} />
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <FormattedMessage {...messages.siteSettingsAccessText2} />
              </Typography>
            </Grid>

            {isSiteBleFFEnable && (
              <Grid item lg={4} md={12} xs={12}>
                <Typography variant="body1" fontWeight={700}>
                  <FormattedMessage {...messages.siteSettingsAccessTitle3} />
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  <FormattedMessage {...messages.siteSettingsAccessText3} />
                </Typography>
                {!siteDataIsLoading && (
                  <>
                    {siteData?.services.ACCESS_CONTROL?.bluetoothEnabled === undefined ? (
                      <>
                        <Alert
                          severity="warning"
                          title={formatMessage(messages.siteSettingsAccessMy2NappWarTitle)}
                          variant="outlined"
                        >
                          <FormattedMessage {...messages.siteSettingsAccessMy2NappWarText3} />
                        </Alert>
                        <Button
                          variant="outlined"
                          startIcon={<SettingsIcon />}
                          onClick={openConfigureMy2NAppSettingsModal}
                        >
                          <FormattedMessage {...messages.siteSettingsAccessMy2NappButton} />
                        </Button>
                      </>
                    ) : (
                      <>
                        <Checkbox
                          name={FormFieldNames.SiteBLE}
                          label={formatMessage(messages.siteSettingsAccessMy2NappLabel)}
                          onClick={(event) => {
                            event.preventDefault();
                            formMethods.getValues(FormFieldNames.SiteBLE)
                              ? openDeactivateMy2NAppAccessModal()
                              : openConfigureMy2NAppSettingsModal();
                          }}
                        />
                        {formMethods.getValues(FormFieldNames.SiteBLE) && !!siteCredentialsSettingData?.locationId && (
                          <FormHelperText
                            sx={{
                              mt: 0,
                              pl: 4,
                            }}
                          >
                            <FormattedMessage
                              {...messages.siteSettingsAccessMy2NappHelp}
                              values={{
                                link: (
                                  <Link href={formatMessage(messages.siteSettingsAccessMy2NappHelpLinkUrl)}>
                                    <FormattedMessage {...messages.siteSettingsAccessMy2NappHelpLinkText} />
                                  </Link>
                                ),
                                locationId: siteCredentialsSettingData.locationId,
                              }}
                            />
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}

            <Grid item xs={12} sx={styles.buttons}>
              <Button color="secondary" onClick={() => formMethods.reset()}>
                <FormattedMessage {...messages.siteSettingsAccessCancel} />
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={
                  !formMethods.formState.isValid ||
                  !formMethods.formState.isDirty ||
                  updateSiteGuestPinSettingsIsLoading
                }
                startIcon={<DoneIcon />}
                variant="contained"
              >
                <FormattedMessage {...messages.siteSettingsAccessSubmit} />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
      <DeactivateMy2NAppAccess onSubmit={handleDisableMy2NAppAccess} />
      <ConfigureMy2NAppAccess refetchSiteData={refetchSiteData} />
    </FormProvider>
  );
}

export default Access;
