import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../components';
import { useHasFeature } from '../../../components/Feature/Feature';
import { CONFIGURATION_MODE, CONNECTION_MODE } from '../../../config/devices';
import featureFlags from '../../../config/featureFlags';
import { SITE_TYPE } from '../../../config/sites';
import { isAnsweringUnit } from '../../../helpers/devices';
import useBreakpoints from '../../../helpers/useBreakpoints';
import apartmentActions, { selectors as apartmentsSelectors } from '../../../modules/apartments';
import actions, { selectors as deviceSelectors } from '../../../modules/devices';
import { handleSaveMultipleDevicesCallSettings } from '../../../modules/devices/helpers/saveDeviceHelper';
import { saveMultipleDevicesCallSettingsRequest } from '../../../modules/devices/saveActions';
import { selectors as sitesSelectors } from '../../../modules/sites';
import { selectors as userSelectors } from '../../../modules/user';
import { add2NDevice } from '../actions';
import AddDeviceModal from '../AddDeviceModal';
import CallSettingsStep from '../CallSettingsStep';
import { ICallSettingsStepData } from '../CallSettingsStep/CallSettingsStep';
import messagesCallSettings from '../CallSettingsStep/messages';
import DeviceTitle from '../DeviceControl/DeviceTitle';
import FinishedStep from '../FinishedStep';
import messagesFinished from '../FinishedStep/messages';
import { getValidationParameters } from '../helpers';
import Loading from './Loading';
import messagesStepOne from './OneStep/messages';
import { OneStep } from './OneStep/OneStep';
import messagesStepTwo from './TwoStep/messages';
import { TwoStep } from './TwoStep/TwoStep';
import { getX2NDeviceValidationSchema, IFormData } from './validationSchema';

export interface IX2NDeviceForm {
  onClearModal: () => void;
  onClose: () => void;
  onSelectDevice: (device: any) => void;
  open: boolean;
}

export function X2NDeviceForm({ onClearModal, onClose, onSelectDevice, open }: IX2NDeviceForm) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [callSettings, setCallSettings] = useState<ICallSettingsStepData | undefined>(undefined);
  const { upSm } = useBreakpoints();
  const validationExistName = useSelector(getValidationParameters);
  const { companyId, siteId } = validationExistName;
  const currentUser = useSelector(userSelectors.getCurrentUserSelector);
  const finishedData = useSelector(deviceSelectors.getFinishedDataDeviceModal);
  const floors = useSelector(apartmentsSelectors.getFloors);
  const isAdvanceFinished = useSelector(deviceSelectors.isAdvanceFinishedAddDeviceModal);
  const isFinished = useSelector(deviceSelectors.isFinishedAddDeviceModal);
  const siteType = useSelector(sitesSelectors.getCurrentSiteType);
  const licenceModel = useSelector(sitesSelectors.getSitesServiceMobileVideoLicenceModel);
  const isWait = useSelector(deviceSelectors.isWaitAddDeviceModal);
  const my2nId = useSelector(sitesSelectors.getMy2nId);
  const freeMVFFEnable = useHasFeature(featureFlags.FREE_MV_FOR_APARTMENT);

  useEffect(() => {
    dispatch(apartmentActions.getApartmentsList(companyId, siteId));
    dispatch(apartmentActions.getSiteFloorsList(companyId, siteId));
  }, []);

  const isSiteMdu = siteType === SITE_TYPE.MDU;

  const formData = useForm<IFormData>({
    defaultValues: {
      apartmentId: undefined,
      configurationMode: CONFIGURATION_MODE.AUTOMATIC,
      connectionMode: CONNECTION_MODE.LOCAL,
      currentStep: step,
      deviceType: '',
      existUser: true,
      freeLicenceCount: undefined,
      hasForm: false,
      hasSelectedApartment: false,
      identifier: '',
      isDeviceIntercomOrAccessUnit: false,
      isIDT: false,
      isSiteMDU: isSiteMdu,
      name: '',
      securityCode: '',
      serialNumber: '',
      storeHash: false,
      user: currentUser?.id,
      userId: currentUser.id,
    },
    mode: 'onChange',
    resolver: yupResolver(
      getX2NDeviceValidationSchema(formatMessage, companyId, siteId, step)
    ) as unknown as Resolver<IFormData>,
  });

  useEffect(() => {
    if (isWait && step !== 3) {
      formData.setValue('currentStep', 3);
      setStep(3);
    } else if (isFinished && step < 4) {
      if (isAnsweringUnit(finishedData?.type)) {
        formData.setValue('currentStep', 4);
        setStep(4);
      } else {
        formData.setValue('currentStep', 5);
        setStep(5);
      }
    } else if (isFinished && isAdvanceFinished && step < 5) {
      formData.setValue('currentStep', 5);
      setStep(5);
    }
  }, [isWait, isFinished, isAdvanceFinished, step]);

  useEffect(() => {
    if (formData.formState.isSubmitting) {
      setIsSubmitting(true);
    }
  }, [formData.formState.isSubmitting]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (step === 1) {
      formData.setValue('currentStep', 2);
      setStep(2);
    } else {
      dispatch(add2NDevice(formData.getValues(), floors, setStep));
    }
  };

  function onCreateNewDevice() {
    setStep(1);
    formData.reset();
    onSelectDevice(null);
    onClearModal();
    dispatch(actions.addDeviceClearModalData());
  }

  function getActions() {
    if (step === 1) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={() => onCreateNewDevice()} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesStepOne.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...messagesStepOne.formActionCancelBtn} />
          </Button>
          <Button
            color="primary"
            disabled={!formData.formState.isValid}
            endIcon={<ArrowForwardIcon />}
            onClick={handleSubmit}
            variant="contained"
          >
            <FormattedMessage {...messagesStepOne.formActionNextBtn} />
          </Button>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={() => setStep(step - 1)} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesStepTwo.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...messagesStepTwo.formActionCancelBtn} />
          </Button>
          <LoadingButton
            color="primary"
            disabled={!formData.formState.isValid}
            endIcon={<ArrowForwardIcon />}
            isLoading={isSubmitting}
            onClick={handleSubmit}
            variant="contained"
          >
            <FormattedMessage {...messagesStepTwo.formActionNextBtn} />
          </LoadingButton>
        </>
      );
    }

    if (step === 4) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesCallSettings.formActionBackButton} />
            </Button>
          </Box>
          <Button onClick={() => setStep(6)}>
            <FormattedMessage {...messagesCallSettings.formActionFinishLaterButton} />
          </Button>
          <Button
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              if (callSettings?.state.devicesCallSettings.some((device) => device.selected)) {
                handleSaveMultipleDevicesCallSettings(callSettings, (values) =>
                  dispatch(saveMultipleDevicesCallSettingsRequest(values))
                );
              } else {
                setStep(5);
              }
            }}
            type="button"
            variant="contained"
          >
            <FormattedMessage {...messagesCallSettings.formActionFinishedButton} />
          </Button>
        </>
      );
    }

    if (step >= 5) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messagesFinished.formActionBackBtn} />
            </Button>
          </Box>
          <Button color="primary" onClick={() => onCreateNewDevice()}>
            <FormattedMessage {...messagesFinished.formActionAddNewDeviceBtn} />
          </Button>
          <Button color="primary" endIcon={<DoneIcon />} onClick={onClose} type="submit" variant="contained">
            <FormattedMessage {...messagesFinished.formActionCloseBtn} />
          </Button>
        </>
      );
    }

    return null;
  }

  return (
    <FormProvider {...formData}>
      <form autoComplete="off" noValidate>
        <AddDeviceModal actions={getActions()} title={<DeviceTitle device="X2N" />} open={open} onClose={onClose}>
          {step === 1 && (
            <OneStep
              freeMVFFEnable={freeMVFFEnable}
              companyId={companyId}
              floors={floors}
              isMobileView={!upSm}
              isShowApartmentField
              my2nId={my2nId}
              siteType={siteType}
              siteMVStatus={licenceModel?.status}
              siteMVType={licenceModel?.type}
            />
          )}
          {step === 2 && (
            <TwoStep
              onSetNextStep={() => {
                setStep(3);
              }}
            />
          )}
          {step === 3 && <Loading />}
          {step === 4 && (
            <CallSettingsStep
              addedDevice={finishedData || {}}
              companyId={companyId}
              handleChange={setCallSettings}
              siteId={siteId}
            />
          )}
          {step >= 5 && (
            <FinishedStep
              siteMVStatus={licenceModel?.status}
              siteMVType={licenceModel?.type}
              siteType={siteType}
              freeMVFFEnable={freeMVFFEnable}
              callSettingsSkipped={step === 6}
              device={finishedData}
              deviceModalType={'X2N'}
            />
          )}
        </AddDeviceModal>
      </form>
    </FormProvider>
  );
}
