import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Box from '@mui/material/Box';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../components';
import { VERIFICATION_STATUS } from '../../../config/devices';
import { SITE_TYPE } from '../../../config/sites';
import { isFreeLicenceAlert } from '../X2NDeviceForm/helpers';
import messages from './messages';
import NoVerifiedMessage from './NoVerifiedMessage';
import SuccessMessage from './SuccessMessage';
import WaitingMessage from './WaitingMessage';

export interface IFinishedStep {
  callSettingsSkipped?: boolean;
  device?: {
    verificationStatus: string;
    deviceType: string;
  };
  deviceModalType?: string;
  freeMVFFEnable?: boolean;
  siteType?: SITE_TYPE;
  siteMVStatus?: string;
  siteMVType?: string;
}

const FinishedStep = ({
  callSettingsSkipped,
  device,
  deviceModalType,
  freeMVFFEnable,
  siteMVStatus,
  siteMVType,
  siteType,
}: IFinishedStep) => {
  const { watch } = useFormContext();
  const freeLicenceCount = watch('freeLicenceCount');

  let result = <SuccessMessage callSettingsSkipped={callSettingsSkipped} deviceModalType={deviceModalType} />;
  if (device) {
    if (device.verificationStatus === VERIFICATION_STATUS.PENDING) {
      result = <WaitingMessage callSettingsSkipped={callSettingsSkipped} deviceModalType={deviceModalType} />;
    } else if ([VERIFICATION_STATUS.NOT_VERIFIED, VERIFICATION_STATUS.ZOMBIE].includes(device.verificationStatus)) {
      result = <NoVerifiedMessage />;
    }
  }

  return (
    <Box>
      <Box sx={{ textAlign: 'center' }}>{result}</Box>
      {isFreeLicenceAlert(freeLicenceCount, siteMVType, siteMVStatus, siteType, freeMVFFEnable) && (
        <Alert sx={{ mt: 2 }} severity="success" icon={<NewReleasesIcon />} variant="outlined">
          <FormattedMessage
            {...messages.X2nAddDeviceModalApartmentModelFreeLicenseLastStep}
            values={{ count: freeLicenceCount }}
          />
        </Alert>
      )}
    </Box>
  );
};

export default FinishedStep;
