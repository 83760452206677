import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHasFeature } from '../../../../components/Feature/Feature';
import featureFlags from '../../../../config/featureFlags';
import { BillingType } from '../../../../modules/commonTypes';
import { IMVLicenceModel } from '../../../../modules/sites/store';
import { ICodeData } from '../../../../modules/subscription/store';
import messages from './messages';

interface ICurrentMonthSummary {
  codeData: ICodeData;
  mobileVideoLicense: IMVLicenceModel;
}

function CurrentMonthSummary({ codeData, mobileVideoLicense }: ICurrentMonthSummary) {
  const freeMVFFEnabled = useHasFeature(featureFlags.FREE_MV_FOR_APARTMENT);
  const apartmentBillingType = mobileVideoLicense.billingType === BillingType.PER_APARTMENT;
  const hasFreeLicencesWithIntercom = freeMVFFEnabled && mobileVideoLicense.freeLicencesWithIntercomCount > 0;

  const infoMessage = useMemo(() => {
    if (apartmentBillingType) {
      return {
        label: hasFreeLicencesWithIntercom
          ? messages.currentMonthSummaryInfoApartmentPaidServicesActiveFreeApartment
          : messages.CurrentMonthSummaryInfoApartmentPaidServicesActive,
        title: messages.CurrentMonthSummaryConsumptionAdditional,
      };
    } else {
      return {
        label: messages.currentMonthSummaryInfoDevices,
        title: messages.CurrentMonthSummaryConsumption,
      };
    }
  }, [apartmentBillingType]);

  return (
    <>
      <Typography>
        <FormattedMessage
          {...infoMessage.title}
          values={{
            credits: codeData.activation.initialConsumption.toFixed(1),
          }}
        />
      </Typography>
      <Typography color="textSecondary" variant="body2">
        <FormattedMessage {...infoMessage.label} values={{ br: <br /> }} />
      </Typography>
    </>
  );
}

export default CurrentMonthSummary;
