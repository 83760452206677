/* eslint-disable sort/object-properties */
import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { headSiteData } from '../../../api/siteRequest';
import { DEVICE_SERIAL_CODE_MASK } from '../../../config/devices';
import { TId } from '../../../modules/commonTypes';
import { getNewApartmentValidationSchema, INewApartmentForm } from '../components/NewApartmentForm/validationSchema';
import { getNewUserValidationSchema, INewUserForm } from '../components/UserSelectField/validationSchema';
import messages from './messages';

export interface IFormData extends INewUserForm, INewApartmentForm {
  isSiteMDU?: boolean;
  name: string;
  configurationMode: string;
  connectionMode: string;
  deviceType: string;
  serialNumber: string;
  isDeviceIntercomOrAccessUnit: boolean;
  isIDT: boolean;
  freeLicenceCount: number;
  storeHash: boolean;
  identifier?: string;
  securityCode?: string;
  user?: number;
  currentStep: number;
}

export function getX2NDeviceValidationSchema(
  formatMessage: IntlShape['formatMessage'],
  companyId: TId,
  siteId: TId,
  step: number
) {
  return Yup.object().shape({
    existUser: Yup.boolean().required(),
    isSiteMDU: Yup.boolean(),
    ...getNewUserValidationSchema(formatMessage, companyId, siteId),
    ...getNewApartmentValidationSchema(formatMessage, companyId, siteId),
    name: Yup.string()
      .required(formatMessage(messages.x2NDeviceFormRequired))
      .max(50, formatMessage(messages.x2NDeviceFormTooLong))
      .test({
        message: formatMessage(messages.x2NDeviceFormNameExist),
        name: 'isExistName',
        test: async (name) => {
          try {
            let state = true;
            if (!name || name.length === 0) {
              return true;
            }
            await headSiteData({ companyId, siteId }, 'devices', { name }, false).then((response) => {
              state = response.status === 404;
            });

            return state;
          } catch (error) {
            return true;
          }
        },
      }),
    configurationMode: Yup.string().required(formatMessage(messages.x2NDeviceFormRequired)),
    connectionMode: Yup.string().required(formatMessage(messages.x2NDeviceFormRequired)),
    deviceType: Yup.string().required(formatMessage(messages.x2NDeviceFormRequired)),
    identifier: Yup.string().when(['isIDT', 'currentStep'], {
      is: (isIDT: boolean, currentStep: number) => isIDT && currentStep === 2,
      then: (schema) =>
        schema
          .max(30, formatMessage(messages.x2NDeviceFormTooLong))
          .test(
            'isExistIdentifier',
            formatMessage(messages.x2NDeviceFormRequired),
            (value) => (step === 1 && !value) || (value !== undefined && value.length > 0)
          )
          .test('isValidIdentifier', formatMessage(messages.x2NDeviceFormIdentifierNoValid), (value) =>
            new RegExp(/^[\w-]+$/i).test(value ?? '')
          ),
    }),
    securityCode: Yup.string().when('isIDT', {
      is: false,
      then: (schema) =>
        schema
          .required(formatMessage(messages.x2NDeviceFormRequired))
          .test('isValidSecurityCode', formatMessage(messages.x2NDeviceFormSecurityCodeNoValid), (value) =>
            new RegExp(/^([A-Z0-9]{4})-?([A-Z0-9]{4})-?([A-Z0-9]{4})-?([A-Z0-9]{4})$/i).test(value)
          ),
    }),
    serialNumber: Yup.string()
      .required(formatMessage(messages.x2NDeviceFormRequired))
      .test('isValidSerialNumber', formatMessage(messages.x2NDeviceFormSerialNumberNoValid), (value) =>
        new RegExp(DEVICE_SERIAL_CODE_MASK).test(value)
      )
      .test({
        name: 'serialNumber',
        test: async (serialNumber: string, context) => {
          if (serialNumber && new RegExp(/^(\d{2}-\d{4}-\d{4})$/).test(serialNumber)) {
            try {
              let error = undefined;
              if (!serialNumber || serialNumber.length === 0) {
                return true;
              }

              await headSiteData({ companyId, siteId }, `device-meta/${serialNumber}`, {}, false)
                .then((response) => {
                  if (response.status === 409) {
                    error = context.createError({
                      message: formatMessage(messages.serialNumberControlNoUnique),
                      path: 'serialNumber',
                    });
                  }
                })
                .catch(() => {
                  error = context.createError({
                    message: formatMessage(messages.serialNumberControlInvalid),
                    path: 'serialNumber',
                  });
                });

              return error ?? true;
            } catch (error) {
              return true;
            }
          }

          return true;
        },
      }),
  });
}
