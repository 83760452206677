import { Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { OfflinePairingDto, useGetOfflinePairingQuery } from '../../../apiRtk/accessControlApi';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { getDefaultValues } from '../validationSchema';
import messages from './messages';

interface IStep2 {
  setStep: (step: number | undefined) => void;
  setOfflinePairingData: React.Dispatch<React.SetStateAction<OfflinePairingDto>>;
}

function Step2({ setOfflinePairingData, setStep }: IStep2) {
  const formContext = useFormContext();
  const { companyId, siteId } = useGetLoadedParams();
  const {
    data: offlinePairingData,
    isFetching: offlinePairingIsFetching,
    isLoading: offlinePairingIsLoading,
    isSuccess: offlinePairingIsSuccess,
  } = useGetOfflinePairingQuery(
    {
      companyId: companyId!.toString(),
      siteId: siteId!.toString(),
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (offlinePairingIsSuccess) {
      setOfflinePairingData(offlinePairingData);
      formContext.reset(getDefaultValues(offlinePairingData));
      setTimeout(() => {
        setStep(2);
        setStep(undefined);
      }, 2000);
    }
  }, [offlinePairingIsLoading, offlinePairingIsFetching]);

  return (
    <Box>
      <Typography sx={{ mb: 2 }}>
        <FormattedMessage {...messages.configureMy2NaccessModal2Text1} />
      </Typography>
      <LinearProgress sx={{ height: '24px' }} />
      <Typography sx={{ mt: 2 }}>
        <FormattedMessage {...messages.configureMy2NaccessModal2Text2} />
      </Typography>
    </Box>
  );
}

export default Step2;
