import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Autocomplete } from '../../../../components/HookFormFields/Autocomplete';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { IFloor } from '../../../../modules/apartments/store';
import messages from './messages';

export interface INewApartmentForm {
  floors: IFloor[];
  isSiteMdu: boolean;
}

export function NewApartmentForm({ floors, isSiteMdu }: INewApartmentForm) {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        mt: 2,
        pl: 2,
        pr: 2,
        pt: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <Autocomplete
            inputSettings={{
              helperText: <FormattedMessage {...messages.newApartmentFloorHelperText} />,
              InputLabelProps: {
                shrink: true,
              },
              label: <FormattedMessage {...messages.newApartmentFloorLabel} />,
              required: isSiteMdu,
            }}
            name="floor"
            inputMode="text"
            options={floors.map((floor) => floor.name)}
            fullWidth
          />
        </Grid>
        <Grid item sm={3}>
          <OutlinedTextField
            helperText={<FormattedMessage {...messages.newApartmentNumberHelperText} />}
            label={<FormattedMessage {...messages.newApartmentNumberLabel} />}
            name="number"
            required
            fullWidth
          />
        </Grid>
        <Grid item sm={6}>
          <OutlinedTextField
            helperText={<FormattedMessage {...messages.newApartmentNameHelperText} />}
            label={<FormattedMessage {...messages.newApartmentNameLabel} />}
            name="apartmentName"
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
}
