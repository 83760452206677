import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from '../../../../components/HookFormFields/Select';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import Loader from '../../../../components/Loader/Loader';
import { selectors as userSelectors } from '../../../../modules/user';
import { IUser } from '../../../../modules/user/store';
import { getUsers } from './helpers/getUsers';
import messages from './messages';
import { INewUserForm } from './validationSchema';

export interface IUserSelectField {
  disableCreateNewUser?: boolean;
  helperText?: string;
  label?: string;
  users: IUser[];
  onSurNameBlur?: (firstName?: string, lastName?: string) => void;
}

export function UserSelectField({ disableCreateNewUser, helperText, label, onSurNameBlur, users }: IUserSelectField) {
  const currentUser = useSelector(userSelectors.getCurrentUserSelector);
  const { formatMessage } = useIntl();
  const { getValues, setValue, watch } = useFormContext<INewUserForm>();
  const userId = watch('userId');

  React.useEffect(() => {
    if (userId === undefined || userId === -1) {
      setValue('existUser', false);
    } else {
      setValue('existUser', true);
    }
  }, [userId]);

  const existUser = useMemo(() => {
    return userId !== -1;
  }, [userId]);

  return (
    <>
      {users.length > 0 ? (
        <Select
          helperText={!existUser ? formatMessage(messages.userSelectFieldUserHelperText) : helperText}
          label={label !== undefined ? label : <FormattedMessage {...messages.userSelectFieldUserLabel} />}
          name="userId"
          required
          fullWidth
        >
          {getUsers(users, currentUser, !disableCreateNewUser)}
        </Select>
      ) : (
        <Loader />
      )}
      {!existUser && !disableCreateNewUser && (
        <Box
          sx={{
            bgcolor: 'background.default',
            pb: 0,
            pt: 2,
            px: 2,
          }}
        >
          <FormGroup row>
            <OutlinedTextField
              helperText={<FormattedMessage {...messages.userSelectFieldNewUserEmailHelperText} />}
              label={<FormattedMessage {...messages.userSelectFieldNewUserEmailLabel} />}
              name="email"
              required
              fullWidth
              hasTopAndBottomMargin
            />
          </FormGroup>
          <FormGroup row>
            <Grid container spacing={2}>
              <Grid item xs>
                <OutlinedTextField
                  label={<FormattedMessage {...messages.userSelectFieldNewUserFirstName} />}
                  name="firstName"
                  required
                  fullWidth
                  hasTopAndBottomMargin
                />
              </Grid>
              <Grid item xs>
                <OutlinedTextField
                  label={<FormattedMessage {...messages.userSelectFieldNewUserLastName} />}
                  name="lastName"
                  onBlur={onSurNameBlur !== undefined ? () => onSurNameBlur(getValues('firstName')) : undefined}
                  required
                  fullWidth
                  hasTopAndBottomMargin
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </>
  );
}
