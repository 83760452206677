import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Device2NIcon, FormikFields } from '../../../components';
import ButtonScanQRCode from '../../../components/ButtonScanQRCode';
import { DEVICE_SERIAL_CODE_MASK } from '../../../config/devices';
import { SITE_TYPE } from '../../../config/sites';
import DeviceDesktopScanQR from '../../DeviceDesktopScanQR';
import DeviceMobileScanQR from '../../DeviceMobileScanQR';
import ApartmentFormField from '../ApartmentFormField';
import NewApartmentForm from '../NewApartmentForm';
import messages from './messages';
import SecurityCodeControl from './SecurityCodeControl';
import SerialNumberControl from './SerialNumberControl';
import StoreHashControl from './StoreHashControl';

// eslint-disable-next-line complexity
const OneStep = ({
  apartments,
  companyId,
  deviceTypes,
  floors,
  formProps,
  isMobileView,
  isShowApartmentField,
  my2nId,
  serialNumberDisabled,
  site,
}) => {
  const { formatMessage } = useIntl();
  const [showScanCode, setShowScanCode] = useState(isMobileView);

  const onCloseScanCode = () => setShowScanCode(false);

  const onToggleScanCode = () => setShowScanCode((prev) => !prev);

  const onScanCodeSuccess = (options) => {
    if (options) {
      formProps.setFieldValue('serialNumber', options.serialNumber);
      formProps.setFieldValue('securityCode', options.securityCode);
      setShowScanCode(false);
    }
  };

  useEffect(() => {
    if (isMobileView !== showScanCode) {
      setShowScanCode(isMobileView);
    }
  }, [isMobileView]);

  if (isMobileView && showScanCode) {
    return <DeviceMobileScanQR onClose={onCloseScanCode} onSuccess={onScanCodeSuccess} />;
  }

  return (
    <>
      <Typography>
        <FormattedMessage {...messages.x2nDeviceOneStepSerialNumberParagraph} />
      </Typography>
      {!showScanCode ? (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs>
            <SerialNumberControl
              companyId={companyId}
              deviceTypes={deviceTypes}
              disabled={serialNumberDisabled}
              site={site}
            />
            <SecurityCodeControl
              disabled={formProps.values?.isIDT}
              helperText={formatMessage(
                formProps.values?.isIDT
                  ? messages.x2nDeviceOneStepSecurityCodeThisDeviceNotRequiredHint
                  : messages.x2nDeviceOneStepSecurityCodeSomeDevicesNotRequiredHint
              )}
            />
          </Grid>
          <Grid item sx={{ display: { sm: 'block', xs: 'none' } }} xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <Device2NIcon
                deviceType={formProps.values?.deviceType}
                noFound={
                  formProps.values?.serialNumber?.length === 12 &&
                  new RegExp(DEVICE_SERIAL_CODE_MASK).test(formProps.values?.serialNumber) &&
                  formProps.values?.deviceType === ''
                }
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <DeviceDesktopScanQR onSuccess={onScanCodeSuccess} />
      )}
      <Box sx={{ mb: 2 }}>
        <ButtonScanQRCode onClick={onToggleScanCode} open={showScanCode} />
      </Box>
      <StoreHashControl />
      <FormikFields.Input
        helperText={<FormattedMessage {...messages.x2nDeviceOneStepNameHelperText} />}
        label={<FormattedMessage {...messages.x2nDeviceOneStepNameLabel} />}
        name="name"
        required
      />
      {isShowApartmentField && (
        <>
          <ApartmentFormField
            apartments={apartments}
            disabled={
              !(formProps.values?.isAvailableForApartment || formProps.values?.isDeviceIntercomOrAccessUnit || false)
            }
            label={<FormattedMessage {...messages.x2nDeviceOneStepApartmentLabel} />}
            onChange={formProps.handleChange}
            required={!formProps.values?.isDeviceIntercomOrAccessUnit && site.type === SITE_TYPE.MDU}
            value={formProps.values?.apartmentId}
          />
          {formProps.values?.apartmentId === 'createNewApartment' && (
            <Box my={2}>
              <NewApartmentForm
                floors={floors}
                onChange={formProps.handleChange}
                siteType={site.type}
                values={formProps.values}
              />
            </Box>
          )}
        </>
      )}
      {formProps.values?.isIDT !== true && (
        <Alert severity="info">
          <FormattedMessage
            {...messages.x2nDeviceOneStepSecurityCodeInfoBox}
            values={{
              my2nId,
              url: (chunks) => (
                <Link
                  href="https://www.2n.com/en-GB/faqs/2n-ip-intercom-into-my2n-i-dont-have-a-card-with-the-security-code-kA0Rn000000r5mLKAQ?category=management-platforms&product=my2n-management-platform"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Alert>
      )}
    </>
  );
};

OneStep.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object),
  companyId: PropTypes.number.isRequired,
  deviceTypes: PropTypes.arrayOf(PropTypes.object),
  floors: PropTypes.arrayOf(PropTypes.object),
  formProps: PropTypes.shape({
    handleChange: PropTypes.func,
    isValid: PropTypes.bool,
    isValidating: PropTypes.bool,
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({
      apartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      deviceType: PropTypes.string,
      isAvailableForApartment: PropTypes.bool,
      isDeviceIntercomOrAccessUnit: PropTypes.bool,
      isIDT: PropTypes.bool,
      serialNumber: PropTypes.string,
    }),
  }).isRequired,
  isMobileView: PropTypes.bool,
  isShowApartmentField: PropTypes.bool,
  my2nId: PropTypes.string.isRequired,
  serialNumberDisabled: PropTypes.bool,
  site: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
};

OneStep.defaultProps = {
  apartments: [],
  deviceTypes: [],
  floors: [],
  isMobileView: false,
  isShowApartmentField: false,
  serialNumberDisabled: false,
};

export default OneStep;
