import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OfflinePairingDto } from '../../../apiRtk/accessControlApi';
import messages from './messages';
import Questions2 from './Questions2/Questions2';

interface IStep4 {
  offlinePairingData: OfflinePairingDto;
}

function Step4({ offlinePairingData }: IStep4) {
  return (
    <Box>
      <Typography variant="subtitle1" pb={2}>
        <FormattedMessage {...messages.configureMy2NAccessModal4Text1} />
      </Typography>
      <Questions2 offlinePairingDto={offlinePairingData} />
    </Box>
  );
}

export default Step4;
