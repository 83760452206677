import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomDate, FormAction, FormikFields } from '../../../../components';
import { LICENSE } from '../../../../config/subscription';
import PaymentConfirm from '../../Common/PaymentConfirm';
import TotalPriceBar from '../../Common/TotalPriceBar';
import { getOptions } from '../../helpers';
import messages from '../../messages';
import { nextPaymentPropTypes, paymentPropTypes } from '../../unitProps';
import PriceMessage from './PriceMessage';

const OneStep = ({
  calculation,
  currency,
  formProps: { values },
  hasFreeLicencesWithIntercom,
  nextPayment,
  regularPayment,
  setIsOpenPricingModal,
}) => {
  const intl = useIntl();
  const regularPaymentFrequency = regularPayment[values.product];
  const nextPaymentFrequency = nextPayment[values.product];
  const calculationFrequency = calculation[values.product];
  const typographyColor = { color: values.isRecurringPayment ? 'inherit' : 'secondary' };
  const strongChunks = { strong: (chunks) => <strong>{chunks}</strong> };

  const paymentValues = (payment) => ({
    values: {
      ...strongChunks,
      price: intl.formatNumber(payment.price, { currency, style: 'currency' }),
      priceVat: intl.formatNumber(payment.priceVat, { currency, style: 'currency' }),
    },
  });

  const isMDU = () =>
    [LICENSE.APARTMENT_MOBILE_VIDEO_MONTH, LICENSE.APARTMENT_MOBILE_VIDEO_YEAR].includes(values.product);

  return (
    <Grid container>
      <Grid item sx={{ pb: 1 }}>
        <Typography fontWeight="bold">
          <FormattedMessage {...messages.mobileVideoLicenseTitle} />
          <Link onClick={() => setIsOpenPricingModal(true)} sx={{ '&:hover': { cursor: 'pointer' } }} underline="hover">
            <FormattedMessage {...messages.mobileVideoLicenseTitleLink} />
          </Link>
        </Typography>
      </Grid>
      <Grid container sx={{ pb: 2 }}>
        <Grid item xs={8}>
          <FormikFields.RadioList
            id="subscription-fee-radio-list"
            name="product"
            options={getOptions(regularPayment)}
            sx={{ color: 'secondary.main', pl: 2.5 }}
          />
        </Grid>
        <Grid container direction="column" item justifyContent="space-around" sx={{ pl: 1.5 }} xs={4}>
          {(regularPayment[LICENSE.MOBILE_VIDEO_MONTH] || regularPayment[LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]) && (
            <Grid alignItems="center" container item>
              <PriceMessage
                currency={currency}
                payment={
                  regularPayment[LICENSE.MOBILE_VIDEO_MONTH] || regularPayment[LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]
                }
              />
            </Grid>
          )}
          {(regularPayment[LICENSE.MOBILE_VIDEO_YEAR] || regularPayment[LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]) && (
            <Grid alignItems="center" container item>
              <PriceMessage
                currency={currency}
                payment={
                  regularPayment[LICENSE.MOBILE_VIDEO_YEAR] || regularPayment[LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container direction="column" sx={{ pb: 2 }}>
        <Grid item sx={{ pb: 1 }}>
          <Typography fontWeight="bold">
            <FormattedMessage {...messages.mobileVideoLicenseNumberOfLicencesTitle} />
          </Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom sx={{ pl: 2.5 }}>
            {isMDU() ? (
              <FormattedMessage
                {...(hasFreeLicencesWithIntercom
                  ? messages.mobileVideoLicenseNumberOfLicencesApartmentFreeApartment
                  : messages.mobileVideoLicenseNumberOfLicencesApartment)}
                values={{
                  ...strongChunks,
                  licences: calculationFrequency.licences,
                }}
              />
            ) : (
              <FormattedMessage
                {...messages.mobileVideoLicenseNumberOfLicencesDevices}
                values={{
                  ...strongChunks,
                  licences: calculationFrequency.licences,
                }}
              />
            )}
          </Typography>
        </Grid>
      </Grid>
      <TotalPriceBar
        currency={currency}
        price={calculationFrequency.price}
        priceVat={calculationFrequency.priceVat}
        totalPriceNote={
          [LICENSE.MOBILE_VIDEO_YEAR, LICENSE.APARTMENT_MOBILE_VIDEO_YEAR].includes(values.product) ? (
            <FormattedMessage
              {...messages.totalPriceBarTotalPriceTitleNote}
              values={{
                monthsNote: <FormattedMessage {...messages.totalPriceBarMonthsNote} />,
              }}
            />
          ) : null
        }
      />
      <Grid item sx={{ pb: 2 }}>
        <PaymentConfirm
          description={<FormattedMessage {...messages.mobileVideoLicenseRecurringPaymentDescription} />}
          message={<FormattedMessage {...messages.mobileVideoLicenseRecurringPaymentTitle} />}
          name="isRecurringPayment"
        />
      </Grid>
      <Grid container direction="column" item sx={{ ml: 2.5 }}>
        <Box pb={1}>
          <Typography {...typographyColor} variant="body2">
            <FormattedMessage
              {...messages.mobileVideoLicenseNextScheduledPayment}
              values={{
                ...strongChunks,
                date: <CustomDate dateValue={nextPaymentFrequency.date} />,
              }}
            />
          </Typography>
          <Typography {...typographyColor} variant="body2">
            <FormattedMessage
              {...messages.mobileVideoLicenseNextPayment}
              {...paymentValues({
                price: +nextPaymentFrequency.calculation.price.toFixed(2),
                priceVat: +nextPaymentFrequency.calculation.priceVat.toFixed(2),
              })}
            />
            {nextPaymentFrequency.calculation.licencesPool > 0 && (
              <FormattedMessage
                {...messages.mobileVideoLicenseNextPaymentLicensePool}
                values={{
                  ...strongChunks,
                  licencesPool: nextPaymentFrequency.calculation.licencesPool.toFixed(2),
                }}
              />
            )}
          </Typography>
        </Box>
        <Typography {...typographyColor} variant="body2">
          {isMDU() ? (
            <FormattedMessage
              {...messages.mobileVideoLicenseNumberOfLicencedDevicesApartment}
              values={{
                ...strongChunks,
                number: nextPaymentFrequency.calculation.licences,
              }}
            />
          ) : (
            <FormattedMessage
              {...messages.mobileVideoLicenseNumberOfLicencedDevicesDevices}
              values={{
                ...strongChunks,
                number: nextPaymentFrequency.calculation.licences,
              }}
            />
          )}
        </Typography>
        <Typography {...typographyColor} variant="body2">
          {[LICENSE.MOBILE_VIDEO_YEAR, LICENSE.APARTMENT_MOBILE_VIDEO_YEAR].includes(values.product) ? (
            <FormattedMessage
              {...messages.mobileVideoLicenseRegularAnnualPayment}
              {...paymentValues(regularPaymentFrequency)}
            />
          ) : (
            <FormattedMessage
              {...messages.mobileVideoLicenseRegularMonthlyPayment}
              {...paymentValues(regularPaymentFrequency)}
            />
          )}
        </Typography>
      </Grid>
      <Grid alignItems="center" container justifyContent="flex-end">
        <FormAction
          disabled={!values.isRecurringPayment}
          submitText={<FormattedMessage {...messages.subscriptionButtonNext} />}
          type="submit"
        />
      </Grid>
    </Grid>
  );
};

OneStep.propTypes = {
  calculation: paymentPropTypes.isRequired,
  currency: PropTypes.string.isRequired,
  formProps: PropTypes.shape({
    isValid: PropTypes.bool,
    values: PropTypes.shape({
      isRecurringPayment: PropTypes.bool,
      product: PropTypes.string,
    }),
  }).isRequired,
  hasFreeLicencesWithIntercom: PropTypes.bool,
  nextPayment: nextPaymentPropTypes.isRequired,
  regularPayment: paymentPropTypes.isRequired,
  setIsOpenPricingModal: PropTypes.func.isRequired,
};

export default OneStep;
