import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

interface ILocationIDs {
  locationIds: string[];
}

function LocationIDs({ locationIds }: ILocationIDs) {
  const { formatMessage } = useIntl();
  return (
    <>
      {locationIds.length <= 3 ? (
        <>
          <Typography>
            <FormattedMessage {...messages.configureMy2NaccessModal3Text1MaybeBluetooth} />
          </Typography>
          <Typography sx={{ pt: 2 }}>
            <FormattedMessage
              {...messages.configureMy2NaccessModal3Text2MoreIds}
              values={{ count: locationIds.length }}
            />
          </Typography>
          <Typography variant="body1">
            <ul style={{ lineHeight: '40px', listStyleType: 'none', paddingLeft: '20px' }}>
              {locationIds?.map((locationId, index) => <li key={'locationId' + index}>{locationId}</li>)}
            </ul>
          </Typography>
          <Typography variant="body1" fontSize={'12px'} lineHeight={'14px'}>
            <FormattedMessage
              {...messages.configureMy2NaccessModal3Text3}
              values={{
                br: <br />,
                link: (
                  <Link href={formatMessage(messages.configureMy2NaccessModal3Text3LinkUrl)}>
                    <FormattedMessage {...messages.configureMy2NaccessModal3Text3LinkText} />
                  </Link>
                ),
              }}
            />
          </Typography>
        </>
      ) : (
        <>
          <Typography>
            <FormattedMessage {...messages.configureMy2NaccessModal3Text1Bluetooth} />
          </Typography>
          <Typography py={2}>
            <FormattedMessage {...messages.configureMy2NaccessModal3Text2ManyIds} />
          </Typography>
          <Typography variant="body1" fontSize={'12px'} lineHeight={'14px'}>
            <FormattedMessage
              {...messages.configureMy2NaccessModal3Text3}
              values={{
                br: <br />,
                link: (
                  <Link href={formatMessage(messages.configureMy2NaccessModal3Text3LinkUrl)}>
                    <FormattedMessage {...messages.configureMy2NaccessModal3Text3LinkText} />
                  </Link>
                ),
              }}
            />
          </Typography>
        </>
      )}
    </>
  );
}

export default LocationIDs;
