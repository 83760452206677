import { defineMessages } from 'react-intl';

export default defineMessages({
  siteSettingsAccessCancel: {
    id: 'siteSettings.access.cancel',
  },
  siteSettingsAccessGuestLengthErrRange: {
    id: 'siteSettings.access.guestLength.errRange',
  },
  siteSettingsAccessGuestLengthHelper: {
    id: 'siteSettings.access.guestLength.helper',
  },
  siteSettingsAccessGuestLengthLabel: {
    id: 'siteSettings.access.guestLength.label',
  },
  siteSettingsAccessGuestLengthUnit: {
    id: 'siteSettings.access.guestLength.unit',
  },
  siteSettingsAccessGuestQrLabel: {
    id: 'siteSettings.access.guestQR.label',
  },
  siteSettingsAccessMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'siteSettings.access.msgErr',
  },
  siteSettingsAccessMsgSucc: {
    defaultMessage: 'Settings updated successfully',
    id: 'siteSettings.access.msgSucc',
  },
  siteSettingsAccessMy2NappButton: {
    defaultMessage: 'configure',
    id: 'siteSettings.access.My2Napp.button',
  },
  siteSettingsAccessMy2NappHelp: {
    defaultMessage: 'Used location ID: {locationId}.',
    id: 'siteSettings.access.My2Napp.help',
  },
  siteSettingsAccessMy2NappHelpLinkText: {
    defaultMessage: 'Learn more.',
    id: 'siteSettings.access.My2Napp.help.linkText',
  },
  siteSettingsAccessMy2NappHelpLinkUrl: {
    defaultMessage:
      'https://wiki.2n.com/hip/conf/latest/en/5-konfigurace-interkomu/5-4-sluzby/5-4-1-rizeni-pristupu#listofparameters',
    id: 'siteSettings.access.My2Napp.help.linkUrl',
  },
  siteSettingsAccessMy2NappLabel: {
    defaultMessage: 'Manage My2N app access',
    id: 'siteSettings.access.My2Napp.label',
  },
  siteSettingsAccessMy2NappMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'siteSettings.access.My2Napp.msgErr',
  },
  siteSettingsAccessMy2NappMsgSucc: {
    defaultMessage: 'The configuration was successfully saved',
    id: 'siteSettings.access.My2Napp.msgSucc',
  },
  siteSettingsAccessMy2NappWarText3: {
    defaultMessage: 'Finish the configuration to make sure that My2N app credentials for users are granted correctly.',
    id: 'siteSettings.access.My2Napp.war.text',
  },
  siteSettingsAccessMy2NappWarTitle: {
    defaultMessage: 'My2N app access is not configured',
    id: 'siteSettings.access.My2Napp.war.title',
  },
  siteSettingsAccessSubmit: {
    id: 'siteSettings.access.submit',
  },
  siteSettingsAccessText1: {
    id: 'siteSettings.access.text1',
  },
  siteSettingsAccessText2: {
    id: 'siteSettings.access.text2',
  },
  siteSettingsAccessText3: {
    defaultMessage: 'Info text, info text, info text, info text, info text, info text, info text, info text.',
    id: 'siteSettings.access.text3',
  },
  siteSettingsAccessTitle1: {
    id: 'siteSettings.access.title1',
  },
  siteSettingsAccessTitle2: {
    id: 'siteSettings.access.title2',
  },
  siteSettingsAccessTitle3: {
    defaultMessage: 'My2n app access',
    id: 'siteSettings.access.title3',
  },
});
