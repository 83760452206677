import { Check } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FilledInfoBar } from '../../../../components/_DesignSystemWrappers/InfoBar/FilledInfoBar';
import { PasswordInput } from '../../../../components/HookFormFields/PasswordInput';
import messages from './messages';
import { useAccountActivationForm } from './useAccountActivationForm';
import { FormFieldNames } from './validationScheme';

export interface IActivateAccountFormProps {
  onSuccess: () => void;
}

export function AccountActivationForm(props: IActivateAccountFormProps) {
  const { email, formData, isLoading, isPasswordHelperTextVisible, onFormSubmit } = useAccountActivationForm({
    onSuccess: props.onSuccess,
  });

  return (
    <FormProvider {...formData}>
      <form onSubmit={formData.handleSubmit(onFormSubmit)}>
        <Stack direction="column" spacing={3}>
          <FilledInfoBar>
            <Typography sx={{ wordWrap: 'break-word' }}>
              <FormattedMessage values={{ userEmail: email }} {...messages.ImsActivateAccountInfo} />
            </Typography>
          </FilledInfoBar>
          <PasswordInput
            id="new-password"
            label={<FormattedMessage {...messages.ImsNewPasswordInputLabel} />}
            name={FormFieldNames.Password}
            required
            fullWidth
            helperText={isPasswordHelperTextVisible && <FormattedMessage {...messages.ImsNewPasswordInputHelperText} />}
          />

          <Stack direction="column" spacing={3}>
            <Stack alignItems={'flex-end'}>
              <LoadingButton
                disabled={!formData.formState.isValid}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
                loadingPosition="start"
                startIcon={<Check />}
              >
                <FormattedMessage {...messages.ImsActivateAccountConfirm} />
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
