import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { APARTMENT_STATUS, MOBILE_VIDEO_STATUS } from '../../config/consts';
import { getCountMobileVideoFreeDevices } from '../../helpers/dashboard';
import DeviceForFreeTooltip from '../DeviceForFreeTooltip';
import messages from './messages';
import { styles } from './styles';

const FreeDevices = ({ apartments, devices, isSiteMdu, status }) => {
  const { count } = getCountMobileVideoFreeDevices(devices);
  const countApartment = apartments?.[APARTMENT_STATUS.ACTIVE_FOR_FREE] || 0;
  return (count > 0 || countApartment > 0) &&
    [MOBILE_VIDEO_STATUS.STANDARD, MOBILE_VIDEO_STATUS.TRIAL_ACTIVE, MOBILE_VIDEO_STATUS.TEMPORARY].includes(status) ? (
    <Box sx={styles.text}>
      <DeviceForFreeTooltip isTooltip status={status}>
        <Typography variant="body2">
          <FormattedMessage
            {...(isSiteMdu ? messages.creditPoolCardFreeDevicesApartments : messages.creditPoolCardFreeDevices)}
            values={{
              count,
              countApartment,
              span: (chunks) => (
                <Box component="span" sx={styles.number}>
                  {chunks}
                </Box>
              ),
              text: (chunks) => (
                <Box component="span" sx={styles.textColor}>
                  {chunks}
                </Box>
              ),
            }}
          />
        </Typography>
      </DeviceForFreeTooltip>
    </Box>
  ) : null;
};

FreeDevices.propTypes = {
  apartments: PropTypes.shape({
    [APARTMENT_STATUS.ACTIVE_FOR_FREE]: PropTypes.number,
    [APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS]: PropTypes.number,
    [APARTMENT_STATUS.NO_SERVICE]: PropTypes.number,
    [APARTMENT_STATUS.UNLICENSED]: PropTypes.number,
    [APARTMENT_STATUS.OTHER]: PropTypes.number,
  }).isRequired,
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSiteMdu: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default FreeDevices;
