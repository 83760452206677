import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, LoadingButton } from '../../../../components';
import { PATHS } from '../../../../config/consts';
import OrderPayment from '../../Common/OrderPayment';
import TotalPriceBar from '../../Common/TotalPriceBar';
import { GP_WEBPAY } from '../../constants';
import messages from '../../messages';
import { mobileVideoOrderContentPropTypes } from '../../unitProps';
import OrderList from './OrderList';

const styles = {
  paymentConfirmCheckbox: {
    '& .MuiFormControlLabel-label': {
      typography: 'body2',
    },
    '& .MuiStack-root': {
      display: 'block !important',
    },
    m: 1,
    mt: 0,
  },
};

const TwoStep = ({
  company,
  currency,
  hasFreeLicencesWithIntercom,
  mobileVideoOrderContent,
  mobileVideoPaymentIsLoading,
  mobileVideoPurchaseId,
  mobileVideoPurchaseLocation,
  monthPercentage,
  onEditBilling,
  onStepChange,
}) => {
  const [paymentPrepared, setPaymentPrepared] = useState(false);
  const [gtcAgree, setGtcAgree] = useState(false);

  return (
    <Grid container direction="column">
      <OrderPayment
        company={company}
        currentMethod={GP_WEBPAY}
        onEditBilling={onEditBilling}
        showInvoiceRadio={false}
      />
      <OrderList
        currency={currency}
        hasFreeLicencesWithIntercom={hasFreeLicencesWithIntercom}
        mobileVideoOrderContent={mobileVideoOrderContent}
        monthPercentage={monthPercentage}
      />
      <Grid container item sx={{ mb: 1 }}>
        <TotalPriceBar
          currency={currency}
          price={mobileVideoOrderContent ? mobileVideoOrderContent.price : 0}
          priceVat={mobileVideoOrderContent ? mobileVideoOrderContent.priceVat : 0}
          totalPriceNote={false}
        />
      </Grid>
      {mobileVideoOrderContent && (
        <>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={paymentPrepared}
                  color="primary"
                  name="paymentPrepared"
                  onChange={({ target }) => setPaymentPrepared(target.checked)}
                  required
                />
              }
              label={<FormattedMessage {...messages.twoStepPaymentPrepared} />}
              sx={styles.paymentConfirmCheckbox}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={gtcAgree}
                  color="primary"
                  name="agreeGTC"
                  onChange={({ target }) => setGtcAgree(target.checked)}
                  required
                />
              }
              label={
                <FormattedMessage
                  {...messages.agreeGTCLabel}
                  values={{
                    cloudLink: (chunks) => (
                      <Link target="_blank" to={PATHS.CLOUD_AGREEMENT_WITH_COMPANY.replace(':companyId', company?.id)}>
                        {chunks}
                      </Link>
                    ),
                    consumerLink: (chunks) => (
                      <Link
                        target="_blank"
                        to={PATHS.INFORMATION_FOR_CONSUMERS_WITH_COMPANY.replace(':companyId', company?.id)}
                      >
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              }
              sx={styles.paymentConfirmCheckbox}
            />
          </Grid>
        </>
      )}
      <Grid alignItems="center" container justifyContent="flex-end">
        <Grid item>
          <LoadingButton
            color="primary"
            disabled={!paymentPrepared || !gtcAgree || !mobileVideoOrderContent || !mobileVideoPurchaseId}
            href={mobileVideoPurchaseLocation}
            isLoading={mobileVideoPaymentIsLoading}
            onClick={() => onStepChange((prevStep) => prevStep + 1)}
            target="_blank"
            type="button"
            variant="contained"
          >
            <FormattedMessage {...messages.subscriptionButtonSecurePayment} />
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

TwoStep.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  currency: PropTypes.string.isRequired,
  hasFreeLicencesWithIntercom: PropTypes.bool,
  mobileVideoOrderContent: mobileVideoOrderContentPropTypes,
  mobileVideoPaymentIsLoading: PropTypes.bool.isRequired,
  mobileVideoPurchaseId: PropTypes.string,
  mobileVideoPurchaseLocation: PropTypes.string,
  monthPercentage: PropTypes.number,
  onEditBilling: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
};

TwoStep.defaultProps = {
  mobileVideoOrderContent: null,
  mobileVideoPurchaseId: null,
};

export default TwoStep;
