import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BillingType } from '../../../modules/commonTypes';
import messages from '../messages';

const rootStyle = {
  backgroundColor: 'orange.50',
  color: 'orange.600',
  px: 3,
  py: 2.5,
};

const WarningBox = ({ billingType }: { billingType: BillingType }) => (
  <Box sx={rootStyle}>
    <Typography>
      <FormattedMessage
        {...(billingType === BillingType.PER_DEVICE
          ? messages.mobileVideoLicenseWarningDevices
          : messages.mobileVideoLicenseWarningApartments)}
      />
    </Typography>
  </Box>
);

export default WarningBox;
