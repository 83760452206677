import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectors as apartmentsSelectors } from '../../../../modules/apartments';
import { INewApartmentForm } from '../NewApartmentForm/validationSchema';
import messages from './messages';

const styles = {
  createOption: {
    alignItems: 'center',
    color: 'primary.main',
    display: 'flex',
  },
  menuItem: {
    lineHeight: '22px',
  },
};

export interface IApartmentFormField {
  disabled: boolean;
  required: boolean;
}

export function ApartmentFormField({ disabled, required }: IApartmentFormField) {
  const apartments = useSelector(apartmentsSelectors.getApartmentsListGrouped);
  const { getFieldState, setValue, trigger, watch } = useFormContext<INewApartmentForm>();
  const value = watch('apartmentId');
  const formState = getFieldState('apartmentId');

  function getItems() {
    const items: { id?: number; name: string }[] = [];
    if (!apartments || !Array.isArray(apartments)) {
      return items;
    }

    apartments
      .sort((a) => {
        if (a.floorName === '') {
          return -1;
        }
        if (a.floorName !== '') {
          return 1;
        }
        return 0;
      })
      .forEach((item) => {
        items.push({
          name: item.floorName,
        });
        (item.items || []).forEach((option) => {
          items.push({
            id: option.id,
            name: option.name ? `${option.number} – ${option.name}` : option.number,
          });
        });
      });

    return items;
  }

  function onChangeApartment(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value !== 'createNewApartment') {
      setValue('hasSelectedApartment', true);
    }

    const newValue = parseInt(e.target.value);

    setValue('apartmentId', newValue);
    if (newValue === -1) {
      setValue('hasForm', true);
    } else {
      setValue('hasForm', false);
    }
    trigger();
  }

  return (
    <Box mt={2}>
      <TextField
        disabled={disabled}
        fullWidth
        helperText={
          <FormattedMessage
            {...(formState.invalid
              ? messages.OneStepApartmentErrorDeviceLimitReached
              : messages.apartmentFormFieldHelperText)}
          />
        }
        label={<FormattedMessage {...messages.apartmentFormFieldLabel} />}
        margin="none"
        name="apartmentId"
        error={formState.error !== undefined}
        onChange={onChangeApartment}
        required={required}
        select
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
          },
        }}
        value={value || 'placeholder'}
        variant="outlined"
        InputProps={{
          sx: {
            ...(value === -1 && { color: 'primary.main' }),
          },
        }}
      >
        <MenuItem key={0} sx={styles.menuItem} value="placeholder">
          <FormattedMessage {...messages.apartmentFormFieldEmptySelect} />
        </MenuItem>
        <MenuItem key={-1} sx={styles.createOption} value={-1}>
          <CreateNewFolderIcon fontSize="small" sx={{ mr: 1 }} />
          <FormattedMessage {...messages.apartmentFormFieldCreateNewApartment} />
        </MenuItem>
        {getItems().map((item, index) =>
          item.id ? (
            <MenuItem key={item.id} sx={styles.menuItem} value={item.id}>
              {item.name}
            </MenuItem>
          ) : (
            <ListSubheader disableSticky key={`heading-${index + 1}`}>
              {item.name}
            </ListSubheader>
          )
        )}
      </TextField>
    </Box>
  );
}
