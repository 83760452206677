import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Status } from '../../../components';
import { SUPPORT } from '../../../config/address';
import { PATHS } from '../../../config/consts';
import { PURCHASE_PAYMENT_RESULT, PURCHASE_PAYMENT_STATE, TYPE } from '../../../config/subscription';
import { useUrlParams } from '../../../helpers/urlParams';
import { INVOICE } from '../constants';
import messages from '../messages';
import { purchasePaymentResultPropTypes } from '../unitProps';

// eslint-disable-next-line complexity
const getStatus = (state, result, subscription, companyId, paymentMethod) => {
  const mailToLink = (
    <a href={`mailto:${SUPPORT}`}>
      <FormattedMessage {...messages.paymentStateCustomerCare} />
    </a>
  );

  switch (state) {
    case PURCHASE_PAYMENT_STATE.PAID:
      if (subscription === TYPE.PROMO_CODE) {
        return (
          <Status
            info={
              <FormattedMessage
                {...messages.paymentStateSuccessVouchersDescription}
                values={{
                  url: (chunks) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{chunks}</Link>,
                }}
              />
            }
            label={
              <FormattedMessage
                {...(paymentMethod === INVOICE
                  ? messages.paymentStateSuccessInvoiceLabel
                  : messages.paymentStateSuccessCreditCardLabel)}
              />
            }
            type="success"
          />
        );
      }
      if (subscription === TYPE.INTERCOM_LICENSES) {
        return (
          <Status
            info={
              <FormattedMessage
                {...(paymentMethod === INVOICE
                  ? messages.paymentStateSuccessOtherDescription
                  : messages.paymentStatePaidDescription)}
                values={{
                  br: <br />,
                  url: (chunks) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{chunks}</Link>,
                }}
              />
            }
            label={
              <FormattedMessage
                {...(paymentMethod === INVOICE
                  ? messages.paymentStateSuccessInvoiceLabel
                  : messages.paymentStateSuccessCreditCardLabel)}
              />
            }
            type="success"
          />
        );
      }
      return (
        <Status
          info={
            <FormattedMessage
              {...messages.paymentStatePaidDescription}
              values={{
                br: <br />,
              }}
            />
          }
          label={
            <FormattedMessage
              {...messages.paymentStatePaidLabel}
              values={{
                br: <br />,
              }}
            />
          }
          type="success"
        />
      );

    case PURCHASE_PAYMENT_STATE.DELIVERED:
    case PURCHASE_PAYMENT_STATE.INVOICE_ISSUED:
    case PURCHASE_PAYMENT_STATE.BEFORE_COMPLETION:
      if (subscription === TYPE.PROMO_CODE) {
        return (
          <Status
            info={
              <FormattedMessage
                {...messages.paymentStateSuccessVouchersDescription}
                values={{
                  url: (chunks) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{chunks}</Link>,
                }}
              />
            }
            label={
              <FormattedMessage
                {...(paymentMethod === INVOICE
                  ? messages.paymentStateSuccessInvoiceLabel
                  : messages.paymentStateSuccessCreditCardLabel)}
              />
            }
            type="success"
          />
        );
      }
      if (subscription === TYPE.INTERCOM_LICENSES) {
        return (
          <Status
            info={
              <FormattedMessage
                {...messages.paymentStateSuccessOtherDescription}
                values={{
                  br: <br />,
                  url: (chunks) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{chunks}</Link>,
                }}
              />
            }
            label={
              <FormattedMessage
                {...(paymentMethod === INVOICE
                  ? messages.paymentStateSuccessInvoiceLabel
                  : messages.paymentStateSuccessCreditCardLabel)}
              />
            }
            type="success"
          />
        );
      }
      return (
        <Status
          info={
            <FormattedMessage
              {...messages.paymentStateSuccessOtherDescription}
              values={{
                br: <br />,
                url: (chunks) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{chunks}</Link>,
              }}
            />
          }
          label={<FormattedMessage {...messages.paymentStateSuccessCreditCardLabel} />}
          type="success"
        />
      );
    case PURCHASE_PAYMENT_STATE.COMPLETED:
      if (subscription === TYPE.PROMO_CODE) {
        return (
          <Status
            info={
              <FormattedMessage
                {...messages.paymentStateSuccessVouchersDescription}
                values={{
                  url: (chunks) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{chunks}</Link>,
                }}
              />
            }
            label={
              <FormattedMessage
                {...(paymentMethod === INVOICE
                  ? messages.paymentStateSuccessInvoiceLabel
                  : messages.paymentStateSuccessCreditCardLabel)}
              />
            }
            type="success"
          />
        );
      }
      if (subscription === TYPE.INTERCOM_LICENSES) {
        return (
          <Status
            info={
              <FormattedMessage
                {...messages.paymentStateSuccessCompletedDescription}
                values={{
                  br: <br />,
                  url: (chunks) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{chunks}</Link>,
                }}
              />
            }
            label={
              <FormattedMessage
                {...(paymentMethod === INVOICE
                  ? messages.paymentStateSuccessInvoiceLabel
                  : messages.paymentStateSuccessCreditCardLabel)}
              />
            }
            type="success"
          />
        );
      }
      return (
        <Status
          info={
            <FormattedMessage
              {...messages.paymentStateSuccessCompletedDescription}
              values={{
                br: <br />,
                url: (chunks) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{chunks}</Link>,
              }}
            />
          }
          label={<FormattedMessage {...messages.paymentStateSuccessCreditCardLabel} />}
          type="success"
        />
      );

    case PURCHASE_PAYMENT_STATE.FAILED:
    case PURCHASE_PAYMENT_STATE.CANCELLED:
      return (
        <Status
          info={
            result ? (
              <FormattedMessage
                {...paymentStateMap[result]}
                values={{
                  br: <br />,
                  mailToLink,
                }}
              />
            ) : (
              <FormattedMessage
                {...messages.paymentStateFailedDefault}
                values={{
                  br: <br />,
                  mailToLink,
                }}
              />
            )
          }
          label={<FormattedMessage {...messages.paymentStateFailedLabel} />}
          type="error"
        />
      );
    default:
      return (
        <Status
          info={
            <FormattedMessage
              {...messages.paymentStateInProgressDescription}
              values={{
                br: <br />,
              }}
            />
          }
          label={<FormattedMessage {...messages.paymentStateInProgressLabel} />}
          type="progress"
        />
      );
  }
};

const ThreeStep = ({ paymentMethod, purchasePaymentResult, subscription }) => {
  const { companyId } = useUrlParams();
  return purchasePaymentResult
    ? getStatus(
        purchasePaymentResult.purchaseState,
        purchasePaymentResult.paymentResult,
        subscription,
        companyId,
        paymentMethod
      )
    : getStatus(null, null, null, companyId);
};

ThreeStep.propTypes = {
  paymentMethod: PropTypes.string,
  purchasePaymentResult: purchasePaymentResultPropTypes,
  subscription: PropTypes.string,
};

ThreeStep.defaultProps = {
  paymentMethod: '',
  purchasePaymentResult: null,
  subscription: '',
};

export default ThreeStep;

const paymentStateMap = {
  [PURCHASE_PAYMENT_RESULT.NOT_PROCESSED]: messages.paymentStateFailedNotProcessed,
  [PURCHASE_PAYMENT_RESULT.CARD_ERROR]: messages.paymentStateFailedCardError,
  [PURCHASE_PAYMENT_RESULT.CARD_ISSUER_ERROR]: messages.paymentStateFailedCardIssuerError,
  [PURCHASE_PAYMENT_RESULT.CREDIT_LIMIT_EXCEEDED]: messages.paymentStateFailedCreditLimitExceeded,
  [PURCHASE_PAYMENT_RESULT.GATEWAY_ERROR]: messages.paymentStateFailedGatewayError,
  [PURCHASE_PAYMENT_RESULT.INVOICE_PAYMENT_ERROR]: messages.paymentStateFailedInvoicePaymentError,
  [PURCHASE_PAYMENT_RESULT.MY2N_ERROR]: messages.paymentStateFailedMY2NError,
  [PURCHASE_PAYMENT_RESULT.SESSION_EXPIRED]: messages.paymentStateFailedSessionExpired,
  [PURCHASE_PAYMENT_RESULT.USER_ERROR]: messages.paymentStateFailedUserError,
};
