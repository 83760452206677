import DialpadIcon from '@mui/icons-material/Dialpad';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FE_ROLES, PATHS } from '../../config/consts';
import { useDataGrid } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { isFetchingMessages } from '../../modules/localization/selectors';
import * as siteSelectors from '../../modules/sites/selectors';
import { useAppContext } from '../../pages/App/AppContextProvider';
import ApartmentNameChip from '../ApartmentNameChip';
import DataGrid from '../DataGrid/DataGrid';
import NameColumn from '../DataGrid/NameColumn';
import Link from '../Link';
import UserAccessGroupsModal from '../UserAccessGroupsModal';
import UserAccessSettingsStatus from '../UserAccessSettingsStatus';
import UserApartmentModal from '../UserApartmentModal';
import UserEmail from '../UserEmail';
import UserIcon from '../UserIcon';
import { UserRoleName } from '../UserRoleName';
import messages from './messages';
import SiteUsersRowMenu from './SiteUsersRowMenu/SiteUsersRowMenu';

const SiteUsersDataGrid = ({
  companyId,
  data,
  didInvalid,
  filter,
  isFetching,
  onLoadUsers,
  order,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
  siteId,
}) => {
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadUsers,
    {
      companyId,
      filter,
      order,
      page,
      rowsPerPage,
      siteId,
    },
    didInvalid,
    isFetching
  );

  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { upLg, upSm } = useBreakpoints();
  const { feSiteRoles } = useAppContext();
  const isLocalizationLoaded = useSelector(isFetchingMessages);
  const siteData = useSelector(siteSelectors.getSiteData);

  const [userApartmentModalOpen, setUserApartmentModalOpen] = useState(false);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'lastName',
        flex: 1,
        headerName: formatMessage(messages.siteUsersDataGridColumnsName),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <NameColumn
              isTimeLimitedAccess={!!(cell?.value?.validFrom || cell?.value?.validTo)}
              iconComponent={
                cell?.value?.role === FE_ROLES.GUEST ? <DialpadIcon /> : <UserIcon userRole={cell.value.role} />
              }
              name={cell.value.firstName ? `${cell.value.lastName} ${cell.value.firstName}` : cell.value.lastName}
            />
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'apartment',
        flex: 1,
        headerName: formatMessage(messages.siteUsersDataGridColumnsApartment),
        renderCell: (cell) => {
          if (isFetching) {
            return <Skeleton animation="wave" height={25} width="80%" />;
          }

          const apartments = cell.value.apartments;
          return (
            <ApartmentNameChip
              count={apartments.length}
              name={apartments?.[0]?.name}
              number={apartments?.[0]?.number}
            />
          );
        },
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'email',
        flex: 1,
        headerName: formatMessage(messages.siteUsersDataGridColumnsEmail),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <UserEmail email={cell.value.email} isEnabled={cell.value.enabled} />
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'role',
        flex: 1,
        headerName: formatMessage(messages.siteUsersDataGridColumnsRole),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <UserRoleName userRole={cell.value.role} />
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'access',
        flex: 1,
        headerName: formatMessage(messages.siteUsersDataGridColumnsAccess),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <>
              <UserAccessSettingsStatus
                credentials={cell?.value?.credentials}
                isTimeLimitedAccess={!!(cell?.value?.validFrom || cell?.value?.validTo)}
                siteData={siteData}
              />
            </>
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <SiteUsersRowMenu
              companyId={companyId}
              setUserApartmentModalOpen={() => setUserApartmentModalOpen(true)}
              siteId={siteId}
              user={cell.value}
            />
          ),
        sortable: false,
        width: 65,
      },
    ],
    [isFetching, upSm, upLg, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((user) => ({
        access: user,
        apartment: user,
        email: user,
        id: user.id,
        isClickable: true,
        lastName: user,
        role: user,
        rowMenu: user,
      })),
    [data]
  );

  return (
    <>
      <DataGrid
        columns={columns}
        columnVisibilityModel={{
          access: upSm,
          apartment: upLg,
          email: upLg,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={isFetching}
        loading={isFetching}
        onCellClick={(params) => {
          if (params.field !== 'rowMenu') {
            navigate(
              PATHS.SITE_USER_DETAIL.replace(':companyId', companyId)
                .replace(':siteId', siteId)
                .replace(':userId', params.value?.id)
            );
          }
        }}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={order}
        page={page}
        resultsFiltered={resultsFiltered}
        resultsTotal={resultsTotal}
        rows={rows}
        rowsPerPage={rowsPerPage}
      />
      {siteId !== null && feSiteRoles.isCompanyAdmin && (
        <Box sx={{ bgcolor: 'white', p: 2 }}>
          <Typography variant="body2">
            <FormattedMessage
              {...messages.siteUsersDataGridCompanyAdmins}
              values={{
                link: (
                  <Link to={PATHS.COMPANY_ADMIN.replace(':companyId', companyId)}>
                    <FormattedMessage {...messages.siteUsersDataGridCompanyAdminsLink} />
                  </Link>
                ),
              }}
            />
          </Typography>
        </Box>
      )}
      <UserAccessGroupsModal />
      <UserApartmentModal onClose={() => setUserApartmentModalOpen(false)} open={userApartmentModalOpen} />
    </>
  );
};

SiteUsersDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      companies: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          roles: PropTypes.arrayOf(PropTypes.string),
          sites: PropTypes.arrayOf(
            PropTypes.shape({
              apartments: PropTypes.arrayOf(
                PropTypes.shape({
                  id: PropTypes.number,
                  name: PropTypes.string,
                  number: PropTypes.string,
                  roles: PropTypes.arrayOf(PropTypes.string),
                })
              ),
              id: PropTypes.number,
              name: PropTypes.string,
              roles: PropTypes.arrayOf(PropTypes.string),
            })
          ),
        })
      ),
      credentials: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.string,
      enabled: PropTypes.bool,
      firstName: PropTypes.string,
      id: PropTypes.number,
      lastName: PropTypes.string,
      role: PropTypes.oneOfType([
        PropTypes.shape({
          isCompanyAdmin: PropTypes.bool,
          isSiteAdmin: PropTypes.bool,
          isSiteAdminPayment: PropTypes.bool,
        }),
        PropTypes.string,
      ]),
    })
  ).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadUsers: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default SiteUsersDataGrid;
